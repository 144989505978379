import * as React from 'react';
import { Button, Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ImageGallery from 'react-image-gallery';
import {
  MediaData,
  MediaEntries,
  MediaEntry,
} from '../../../api/property/types';
import {
  getCategoryName,
  getImageUrl,
} from '../../pages/properties/single-view/utils';
import PopupVideoPlayer from '../../molecules/VideoPlayer/PopupVideoPlayer';
import { OrgFileCategory } from '../../../api/orgs/types';
import getCloudinaryPublicImageUrl from '../../../libs/cloudinary/getCloudinaryImageUrl';
import { configResizeGalleryImage } from './config';
import { useStyles } from './DialogGalleryImages.styles';
import determineScreenType from '../../../utils/dom/determineScreenType';
import useWindowSize from '../../../utils/dom/useWindowSize';
import clsx from 'clsx';
import { pushOrgImageIdDisplayedToSessionStorage } from '../../../utils/storage/sessionStorage';

// ========== TYPES ========== //

interface ComponentProps {
  openDialog: boolean;
  startIndex?: number;
  activeCategory?: string;
  mediaEntries?: MediaEntry[] | undefined;
  originalMediaEntries?: MediaEntries | undefined;
  closeDialogGalleryImages: () => void;
  filterImagesByCategory?: (category: string) => void;
  className?: string;
  closeButtonClassName?: string;
  isTracking?: boolean;
}

interface CategoryTab {
  category: OrgFileCategory;
  name: string;
  count: number;
}

// ========== COMPONENT ========== //

export default function DialogGalleryImages({
  openDialog,
  activeCategory,
  mediaEntries,
  originalMediaEntries,
  startIndex = 0,
  closeDialogGalleryImages,
  filterImagesByCategory,
  className,
  closeButtonClassName,
  isTracking,
}: ComponentProps) {
  const classes = useStyles();

  const imageGalleryRef = React.useRef<ImageGallery>(null);
  const [toggleVideo, setToggleVideo] = React.useState<null | boolean>(null);
  const [video, setVideo] = React.useState<MediaData | null>(null);

  const windowSize = useWindowSize();

  const currentScreenType = determineScreenType(windowSize.width);

  const generateGalleryImages = () => {
    return mediaEntries && mediaEntries.length
      ? mediaEntries.map(([imageId, mediaData]: MediaEntry) => {
          const imgUrl = getImageUrl(mediaData);

          const originalUrl = getCloudinaryPublicImageUrl(
            imgUrl,
            configResizeGalleryImage[currentScreenType].original
          );
          const thumbnailUrl = getCloudinaryPublicImageUrl(
            imgUrl,
            configResizeGalleryImage[currentScreenType].thumbnail
          );

          return {
            id: imageId,
            original: originalUrl,
            thumbnail: thumbnailUrl,
            description: getCategoryName(mediaData.category),
            originalClass: classes.originalClass,
            thumbnailClass: classes.thumbnailClass,
          };
        })
      : null;
  };

  const generateCategoriesWithCountItem = (): CategoryTab[] => {
    return Object.values(OrgFileCategory).map(
      (category: OrgFileCategory): CategoryTab => {
        let categoryItems: MediaEntry[] = [];
        if (originalMediaEntries) {
          categoryItems = originalMediaEntries.filter(
            ([imageKey, imageData]: MediaEntry) => {
              return imageData.category === category;
            }
          );
        }
        return {
          category: category,
          name: getCategoryName(category),
          count: categoryItems.length,
        };
      }
    );
  };

  const renderCategoryTabs = (categories: CategoryTab[]) => {
    return (
      <ul className={classes.tabCategory}>
        <li
          onClick={() => {
            closeModal();
            filterImagesByCategory && filterImagesByCategory('all');
          }}
          className={`${classes.tabCategoryItem} ${
            activeCategory === 'all' ? classes.active : ''
          } `}
        >
          All
        </li>
        {categories &&
          categories.map(
            ({ category, name, count }: CategoryTab) =>
              count > 0 && (
                <li
                  className={`${classes.tabCategoryItem} ${
                    activeCategory === category ? classes.active : ''
                  } `}
                  onClick={() => {
                    closeModal();
                    filterImagesByCategory && filterImagesByCategory(category);
                  }}
                  key={category}
                >
                  {name} <span>({count})</span>
                </li>
              )
          )}
      </ul>
    );
  };

  const categories: CategoryTab[] = generateCategoriesWithCountItem();
  const galleryImages = generateGalleryImages();
  const categoryTabs = renderCategoryTabs(categories);

  const closeModal = () => {
    setToggleVideo(null);
  };

  const onSlide = (currentIndex: number) => {
    if (mediaEntries && mediaEntries[currentIndex]) {
      const [mediaKey, mediaData]: MediaEntry = mediaEntries[currentIndex];
      if (mediaData.src_video_url) {
        setToggleVideo(true);
        setVideo(mediaData);
      } else {
        setToggleVideo(false);
        setVideo(null);
        handleTracking(mediaData);
      }
    }
  };

  const handleTracking = (mediaData: MediaData) => {
    if (!isTracking) {
      return;
    }
    pushOrgImageIdDisplayedToSessionStorage(mediaData.orgimages_id);
  };

  const beforeSlide = (nextIndex) => {
    setToggleVideo(false);
  };

  if (mediaEntries === undefined || !mediaEntries.length) {
    return <p>loading...</p>;
  }

  const firstMediaEntry = mediaEntries.length
    ? mediaEntries[startIndex]
    : undefined;

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={closeDialogGalleryImages}
      aria-labelledby="dialog-gallery-images"
    >
      <PopupVideoPlayer
        toggle={
          toggleVideo ??
          (firstMediaEntry ? !!firstMediaEntry[1].src_video_url : false)
        }
        video={video ? video : firstMediaEntry ? firstMediaEntry[1] : null}
      />
      {galleryImages && (
        <div className={clsx(classes.bgImageGallery, className)}>
          {categoryTabs}
          <ImageGallery
            ref={imageGalleryRef}
            showFullscreenButton
            useBrowserFullscreen
            showPlayButton={false}
            lazyLoad
            showIndex
            startIndex={startIndex}
            onBeforeSlide={beforeSlide}
            onSlide={onSlide}
            items={galleryImages}
          />
        </div>
      )}
      <Button
        onClick={() => {
          closeModal();
          closeDialogGalleryImages();
        }}
        className={clsx(classes.closeBtn, closeButtonClassName)}
      >
        <Close />
      </Button>
    </Dialog>
  );
}
