import * as React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { RouteComponentProps, useParams } from '@reach/router';

import { useSendAgencyEmails } from '../../../../api/agencies';
import useProperty from '../../../../api/property/useProperty';
import usePropertyAreaDetails from '../../../../api/property/usePropertyAreaDetails';
import { COOKIE_EXPIRY, COOKIE_NAME } from '../../../../config/cookies';
import { LOCAL_STORAGE_NAME } from '../../../../config/localStorage';
import { useShouldShowAgenciesForm, useSnackbar } from '../../../../hooks';
import updateClientSideCookie from '../../../../utils/cookies/updateClientSideCookie';
import { setItemInLocalStorage } from '../../../../utils/localStorage';

import SEO from '../../../atoms/SEO';
import { Snackbar, SnackbarType } from '../../../molecules';
import { AgenciesFormDialog } from '../../../organisms';
import GenericLoading from '../../../layout/CommonLayout/GenericLoading';
import GenericError from '../../../layout/CommonLayout/GenericError';

import PropertySingleView from './PropertySingleView';

const useStyles = makeStyles(() =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
  })
);

export default function PropertySingleViewPage(_: RouteComponentProps) {
  const classes = useStyles();

  const { developmentId } = useParams();

  const {
    data: propertyData,
    status: propertyStatus,
    error: propertyError,
  } = useProperty({
    developmentId,
    queryConfig: {
      enabled: !!developmentId,
      staleTime: Infinity,
    },
  });

  const { data: areaDetails } = usePropertyAreaDetails({
    developmentId,
    queryConfig: {
      enabled: !!developmentId,
      staleTime: Infinity,
    },
  });

  const { shouldShowAgenciesForm, setShouldShowAgenciesForm } =
    useShouldShowAgenciesForm({ developmentId });

  const { mutate: sendAgencyEmails, status: sendAgencyEmailsStatus } =
    useSendAgencyEmails();

  const { displaySnackbar, hideSnackbar, snackbarState } = useSnackbar();

  React.useEffect(() => {
    if (sendAgencyEmailsStatus === 'success') {
      displaySnackbar(
        SnackbarType.SUCCESS,
        'Thanks! We have received your query.'
      );
    } else if (sendAgencyEmailsStatus === 'error') {
      // Silently fails, but don't hide the Agencies button
    }
  }, [sendAgencyEmailsStatus]);

  return (
    <>
      <SEO
        title={
          propertyData
            ? propertyData.data[0].title
            : `Property ${developmentId} - Loading...`
        }
        showAds
      />

      <Box className={classes.ctn}>
        {propertyData ? (
          <PropertySingleView
            data={propertyData.data}
            nearbyPlaces={propertyData.meta.nearbyPlaces}
            bills={propertyData.meta.bills}
            developmentDetails={propertyData.meta.developmentDetails}
            areaGuide={areaDetails?.data}
          />
        ) : propertyStatus === 'loading' ? (
          <GenericLoading />
        ) : (
          <GenericError error={propertyError} />
        )}
      </Box>

      <AgenciesFormDialog
        open={shouldShowAgenciesForm}
        handleClose={() => {
          setShouldShowAgenciesForm(false);
        }}
        handleCloseAndDontShowAgain={() => {
          setShouldShowAgenciesForm(false);
          updateClientSideCookie({
            data: 'false',
            name: COOKIE_NAME.SHOULD_SHOW_AGENCIES_FORM,
            maxAge: COOKIE_EXPIRY.SHOULD_SHOW_AGENCIES_FORM_AFTER_CANCEL,
          });
        }}
        handleSubmit={({
          agenciesCity,
          agenciesCouncil,
          name,
          email,
          phone,
          bedroom,
          furnishedPref,
          numberOfAdults,
          numberOfChildrenAndTheirAges,
          relationships,
          moveInDate,
          budget,
          occupation,
          income,
          hasPets,
          hasBadDebts,
          hasReferences,
          viewingAvailability,
        }) => {
          sendAgencyEmails({
            agencyArea1: agenciesCouncil ?? [],
            agencyArea2: agenciesCity ?? '',
            tenantName: name,
            tenantEmail: email,
            tenantPhone: phone,
            tenantBedroom: bedroom,
            tenantFurnishedPref: furnishedPref,
            tenantNumberOfAdults: numberOfAdults,
            tenantNumberOfChildrenAndTheirAges: numberOfChildrenAndTheirAges,
            tenantRelationships: relationships,
            tenantMoveInDate: moveInDate,
            tenantBudget: budget,
            tenantOccupation: occupation,
            tenantIncome: income,
            tenantHasPets: hasPets,
            tenantHasBadDebts: hasBadDebts,
            tenantHasReferences: hasReferences,
            tenantViewingAvailability: viewingAvailability,
          });

          setItemInLocalStorage({
            key: LOCAL_STORAGE_NAME.ENQUIRE_FORM_TENANT_DATA,
            value: JSON.stringify({
              name,
              email,
              phone,
              bedroom,
              furnishedPref,
              numberOfAdults,
              numberOfChildrenAndTheirAges,
              relationships,
              moveInDate,
              budget,
              occupation,
              income,
              hasPets,
              hasBadDebts,
              hasReferences,
              viewingAvailability,
            }),
          });

          updateClientSideCookie({
            data: 'false',
            name: COOKIE_NAME.SHOULD_SHOW_AGENCIES_FORM,
            maxAge: COOKIE_EXPIRY.SHOULD_SHOW_AGENCIES_FORM_AFTER_SUBMIT,
          });

          setShouldShowAgenciesForm(false);
        }}
      />

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        {snackbarState.content}
      </Snackbar>
    </>
  );
}
