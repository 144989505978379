import { DEFAULT_COOKIE_FLAGS } from './config';
import getClientSideCookie from './getClientSideCookie';

export interface UpdateClientSideCookieArgs {
  name: string;
  data: string;
  maxAge: number; // in seconds
}

/**
 * Update a client-side cookie if it exists. Return the updated data.
 *
 * If the cookie doesn't exist, will not do anything and return null.
 */
export default function updateClientSideCookie({
  data,
  name,
  maxAge,
}: UpdateClientSideCookieArgs): string | null {
  const existingData = getClientSideCookie(name);

  if (!existingData) {
    return null;
  }

  document.cookie = `${name}=${data};max-age=${maxAge};${DEFAULT_COOKIE_FLAGS}`;
  return data;
}
