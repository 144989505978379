import * as React from 'react';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  colors,
} from '@material-ui/core';
import MarkdownDisplay from '../../../../atoms/MarkdownDisplay/MarkdownDisplay';
import clsx from 'clsx';

// ========== TYPES ========== //

interface ComponentProps {
  developmentDetails: string;
  developmentDetailsExpanded: boolean;
  setDevelopmentDetailsExpanded: (boolean) => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      position: 'relative',

      fontSize: '1rem',
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        fontSize: 'inherit',
      },

      '& a': {
        color: theme.palette.teal.main,
      },
    },

    developmentDetailsExpanded: {
      // maxHeight: '40rem',
      // overflowY: 'auto',
    },

    developmentDetailsCollapsed: {
      maxHeight: '15rem',
      overflowY: 'hidden',
    },

    developmentDetailsCollapsedOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(rgba(255,255,255,0) 50%, rgba(255,255,255, 1))`,
    },

    readMoreBtnCtn: {
      marginTop: theme.spacing(1),
    },

    readMoreBtnRoot: {
      padding: 0,
    },

    readMoreBtnLabel: {
      fontWeight: 'bold',
    },
  })
);

// ========== COMPONENT ========== //

export default function PropertyDetails({
  developmentDetails,
  developmentDetailsExpanded,
  setDevelopmentDetailsExpanded,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Box
        className={
          developmentDetailsExpanded
            ? classes.developmentDetailsExpanded
            : classes.developmentDetailsCollapsed
        }
      >
        <MarkdownDisplay markdownContent={developmentDetails} />
        <Box
          className={clsx(
            !developmentDetailsExpanded &&
              classes.developmentDetailsCollapsedOverlay
          )}
        />
      </Box>
      <Box className={classes.readMoreBtnCtn}>
        <Button
          color="primary"
          onClick={() => setDevelopmentDetailsExpanded((prev) => !prev)}
          classes={{
            root: classes.readMoreBtnRoot,
            label: classes.readMoreBtnLabel,
          }}
        >
          {developmentDetailsExpanded ? 'Collapse' : 'Read more'}
        </Button>
      </Box>
    </Box>
  );
}
