import * as React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import Slider, { Settings as SliderSettings } from 'react-slick';
import clsx from 'clsx';
import { PropertiesSearchSortType } from '../../../../../api/property/types';
import { navigate } from '../../../../../utils/dom';
import GenericLoading from '../../../../layout/CommonLayout/GenericLoading';
import Section from '../Common/Section';
import useProperties from '../../../../../api/property/useProperties/useProperties';
import Emoji from '../../../../atoms/Emoji/Emoji';
import useStyles from './styles';
import SimilarPropertyCard from './SimilarPropertyCard';
import {
  gtmDataLayerPSVSimLeftScrollPush,
  gtmDataLayerPSVSimRightScrollPush,
  gtmDataLayerPSVSimViewPush,
} from '../../../../../utils/marketing/eventPushes/gtmDataLayerPSV';

interface ComponentProps {
  development_id: number;
  address?: string;
}

const CarouselPrevBtn = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        props.className?.replace('slick-prev', ''),
        classes.carouselPrevBtn,
        classes.carouselBtn
      )}
      onClick={props.onClick}
    >
      <KeyboardArrowRight />
    </div>
  );
};

const CarouselNextBtn = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        props.className?.replace('slick-next', ''),
        classes.carouselNextBtn,
        classes.carouselBtn
      )}
      onClick={props.onClick}
    >
      <KeyboardArrowRight />
    </div>
  );
};

export default function SimilarPropertiesSection({
  development_id,
  address,
}: ComponentProps) {
  const classes = useStyles();
  const theme = useTheme();

  const prevArrowClick = () => {
    gtmDataLayerPSVSimLeftScrollPush({
      developmentId: development_id,
    });
  };
  const nextArrowClick = () => {
    gtmDataLayerPSVSimRightScrollPush({
      developmentId: development_id,
    });
  };

  const sliderSettings: SliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: <CarouselPrevBtn onClick={prevArrowClick} />,
    nextArrow: <CarouselNextBtn onClick={nextArrowClick} />,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: theme.breakpoints.values.mm,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          arrows: false,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const { data: propertiesData, isLoading: propertiesIsLoading } =
    useProperties({
      fetchProps: {
        address: `${address}`,
        filters: {
          radius: 10_000,
        },
        sort: PropertiesSearchSortType.RECOMMENDED,
        excludeDevelopmentIds: [development_id],
        pagination: {
          itemsPerPage: 5,
          page: 1,
        },
      },
    });

  const handleViewAllProperties = () => {
    gtmDataLayerPSVSimViewPush({ developmentId: development_id });
    void navigate(`/properties/?address=${address}&radius=10000`);
  };

  return (
    <Section title="SIMILAR PROPERTIES">
      {propertiesData && propertiesData.data.length > 0 ? (
        <Slider {...sliderSettings}>
          {propertiesData.data.map((property, i) => (
            <Grid
              key={property.development_id}
              className={classes.property}
              item
              style={{
                width:
                  i === propertiesData.data.length - 1
                    ? 'calc(100vw - 2rem)'
                    : '75vw',
              }}
            >
              <SimilarPropertyCard property={property} />
            </Grid>
          ))}
        </Slider>
      ) : (
        <Grid container spacing={2}>
          {propertiesIsLoading ? (
            <GenericLoading />
          ) : (
            <Typography>
              There are no similar properties <Emoji emoji="😥" />
            </Typography>
          )}
        </Grid>
      )}
      <Box className={classes.viewAllPropertiesBtnCtn}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewAllProperties}
        >
          View all properties
        </Button>
      </Box>
    </Section>
  );
}
