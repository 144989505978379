import * as React from 'react';

interface ComponentProps {
  emoji: string;
  label?: string;
}

export default function Emoji({ emoji, label }: ComponentProps) {
  return (
    <span role="img" aria-label={label} aria-hidden={!label}>
      {emoji}
    </span>
  );
}
