import { colors, createStyles, makeStyles } from '@material-ui/core';

const IMAGE_HEIGHT = 180;

export default makeStyles((theme) =>
  createStyles({
    ctn: {
      maxHeight: IMAGE_HEIGHT,
      flex: 1,
      border: `1px solid ${colors.grey['300']}`,
      borderRadius: 5,
      overflow: 'hidden',
      '&:not(:first-child)': {
        marginLeft: theme.spacing(0.5),
      },
    },

    ctnNoImages: {
      // display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      display: 'none',
      maxHeight: IMAGE_HEIGHT,
      flex: 1,
      backgroundColor: colors.grey['100'],
    },

    carouselThumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: `center center`,
      cursor: 'pointer',
    },

    photoIcon: {
      position: 'absolute',
      bottom: 0,
      right: '5px',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
    },

    iconSize: {
      fontSize: '1rem',
    },

    carousel: {
      backgroundColor: '#ffffff',
    },

    closeButton: {
      color: '#000000',
    },
  })
);
