import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      maxWidth: theme.custom.container1460,
      padding: 0,
    },

    viewAllPropertiesBtnCtn: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },

    property: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      height: '100%',
    },

    carouselBtn: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(1),
      display: 'flex',
      cursor: 'pointer',
      position: 'absolute',
      top: 'calc(50% - 10px)',
      borderRadius: 5,
      backgroundColor: 'rgba(0,0,0,0.1)',
      '&.slick-disabled': {
        opacity: 0.5,
        cursor: 'unset',
      },
    },

    carouselPrevBtn: {
      left: -40,
      transform: 'scaleX(-1) translateY(-50%)',
    },

    carouselNextBtn: {
      right: -40,
      transform: 'translateY(-50%)',
    },
  })
);
