import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles/PropertyAttribute';

// ========== TYPES ========== //

interface ComponentProps {
  icon: React.ReactNode;
  text: string | React.ReactNode;
}

// ========== COMPONENTS ========== //

export default function PropertyAttribute({ icon, text }: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Box className={classes.icon}>{icon}</Box>
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
}
