import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { colors } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    customAccordion: {
      borderRadius: '0 !important',
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      background: 'transparent',
    },
    customAccordionSummary: {
      padding: '8px 0',
      background: 'transparent',
      '& div': {
        margin: '0 !important',
      },
      '& h6': {
        fontSize: '1rem',
        textTransform: 'capitalize',
        color: colors.grey['900'],
        fontWeight: 400,
      },
      '&.Mui-expanded': {
        zIndex: 1,
        '& h6': {
          fontWeight: 'bold',
          color: theme.palette.primary.main,
        },
      },
    },
    customAccordionDetails: {
      color: colors.grey['900'],
      padding: '0px 16px 16px 0px',
      fontSize: '1rem',
    },
    icon: {
      color: theme.palette.primary.main,
    },
  })
);
