import * as React from 'react';
import { Box, colors, Typography } from '@material-ui/core';
import Section from '../Common/Section';
import useStyles from './styles';
import BtrWallet from '../../../../../images/iconsBtr/BtrWallet';

// ========== TYPES ========== //

interface ComponentProps {
  bills: string[];
}

// ========== COMPONENTS ========== //

function IncludedBills({ bills }: { bills: string[] }) {
  const classes = useStyles();

  return (
    <>
      <Typography>Inclusive of</Typography>

      <Box className={classes.billsCtn}>
        {bills.map((bill) => (
          <Box key={bill} className={classes.billCtn}>
            <BtrWallet stroke={colors.grey['900']} fontSize="large" />
            <Typography>{bill}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

function NoBillsIncluded() {
  return <Typography>Bills are not included</Typography>;
}

export default function BillsSection({ bills }: ComponentProps) {
  const classes = useStyles();

  if (bills.length === 0) {
    return null;
  }

  return (
    <Section title="Your bills" id="your-bills">
      <Box className={classes.ctn}>
        {bills.length > 0 ? (
          <IncludedBills bills={bills} />
        ) : (
          <NoBillsIncluded />
        )}

        <Typography>
          <b>Note:</b> facilities and fares are subject to the final contract.
        </Typography>
      </Box>
    </Section>
  );
}
