import { ImageData } from '../../../../../api/property/types';
import putThumbnailImageOnTop from '../utils/putThumbnailImageOnTop';
import { OrgFileCategory } from '../../../../../api/orgs/types';

interface FlatImagesByCategory {
  [OrgFileCategory.FLAT_IMAGE]: ImageData[];
  [OrgFileCategory.FLOOR_PLAN_IMAGE]: ImageData[];
}

/**
 * Given an array of flat images and an optional flat image thumbnail ID, split
 * the images by flat image categories.
 *
 * If there is a flat image thumbnail ID, will put the thumbnail on top.
 */
export default function splitFlatImagesByCategory(
  flatImages: ImageData[],
  flatImagesThumbnailId?: string | undefined
): FlatImagesByCategory {
  const flatImagesByCategory = flatImages.reduce<FlatImagesByCategory>(
    (acc, cur) => {
      if (cur.category === OrgFileCategory.FLAT_IMAGE) {
        acc[OrgFileCategory.FLAT_IMAGE].push(cur);
      } else if (cur.category === OrgFileCategory.FLOOR_PLAN_IMAGE) {
        acc[OrgFileCategory.FLOOR_PLAN_IMAGE].push(cur);
      }

      return acc;
    },
    {
      [OrgFileCategory.FLAT_IMAGE]: [],
      [OrgFileCategory.FLOOR_PLAN_IMAGE]: [],
    }
  );

  // Put the flat thumbnail image on top if it exists
  if (flatImagesThumbnailId) {
    flatImagesByCategory[OrgFileCategory.FLAT_IMAGE] = putThumbnailImageOnTop(
      flatImagesByCategory[OrgFileCategory.FLAT_IMAGE],
      flatImagesThumbnailId
    );
  }

  return flatImagesByCategory;
}
