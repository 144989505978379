import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { BrokenImage } from '@material-ui/icons';
import useStyles from './styles/FlatImageCarousel';
import {
  ImageData,
  PropertyV2,
  VideoData,
} from '../../../../../api/property/types';
import DialogGalleryImages from '../../../../organisms/ImageGallery/DialogGalleryImages';
import { filterMediaEntriesByCategory, getMediaEntries } from '../utils';
import CloudinaryPicture, {
  CloudinaryPictureSizeConfig,
} from '../../../../atoms/CloudinaryPicture/CloudinaryPicture';
import { OrgFileCategory } from '../../../../../api/orgs/types';
import { pushOrgImageIdDisplayedToSessionStorage } from '../../../../../utils/storage/sessionStorage';

export interface FlatImageCarouselProps {
  property: PropertyV2;
  flatImages: ImageData[];
  flatVideos?: VideoData[];
  thumbnailImage?: string;
  config: CloudinaryPictureSizeConfig;
  category:
    | OrgFileCategory.FLOOR_PLAN_IMAGE
    | OrgFileCategory.FLAT_IMAGE
    | 'all';
}

export default function FlatImageCarousel({
  property,
  flatImages,
  flatVideos = [],
  thumbnailImage,
  config,
  category,
}: FlatImageCarouselProps) {
  const classes = useStyles();
  const originalMediaEntries = getMediaEntries(flatImages, flatVideos);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [mediaEntries, setMediaEntries] = React.useState(originalMediaEntries);
  const [activeCategory, setActiveCategory] = React.useState(category ?? 'all');

  React.useEffect(() => {
    setMediaEntries(originalMediaEntries);
  }, [flatImages]);

  if (flatImages.length === 0) {
    return (
      <Box className={classes.ctnNoImages}>
        <BrokenImage />
        <Typography align="center" variant="body2">
          There are no images available
        </Typography>
      </Box>
    );
  }

  const filterImagesByCategory = (
    category:
      | OrgFileCategory.FLOOR_PLAN_IMAGE
      | OrgFileCategory.FLAT_IMAGE
      | 'all'
  ) => {
    const filteredMediaEntries = filterMediaEntriesByCategory(
      originalMediaEntries,
      category
    );
    if (category === 'all') {
      setMediaEntries(originalMediaEntries);
    } else {
      setMediaEntries(filteredMediaEntries);
    }
    setActiveCategory(category);
  };

  const openDialogGalleryImages = (): void => {
    setOpenDialog(true);
    filterImagesByCategory(category);
  };

  const closeDialogGalleryImages = (): void => {
    setOpenDialog(false);
  };

  const handleOnLoadFlatImage = () => {
    const image = mediaEntries[0]?.find(
      (m) => typeof m === 'object' && m?.file_type === 'image'
    ) as ImageData;

    if (image) {
      pushOrgImageIdDisplayedToSessionStorage(image.orgimages_id);
    }
  };

  return thumbnailImage ? (
    <Box className={classes.ctn}>
      <CloudinaryPicture
        imgSrc={thumbnailImage}
        imgAlt="flat image"
        config={config}
        imgProps={{
          className: classes.carouselThumbnail,
          tabIndex: 0,
          onClick: openDialogGalleryImages,
          onLoad: handleOnLoadFlatImage,
        }}
      />
      <DialogGalleryImages
        closeDialogGalleryImages={closeDialogGalleryImages}
        filterImagesByCategory={filterImagesByCategory}
        openDialog={openDialog}
        mediaEntries={mediaEntries}
        originalMediaEntries={originalMediaEntries}
        startIndex={0}
        activeCategory={activeCategory}
        className={classes.carousel}
        closeButtonClassName={classes.closeButton}
        isTracking={true}
      />
    </Box>
  ) : null;
}
