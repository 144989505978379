import { PropertyV2 } from '../../../../../api/property/types';

export enum FacilitiesAndServicesCategories {
  FACILITIES = 'Facilities',
  AMENITIES = 'Amenities',
  SECURITY = 'Security',
  TERMS = 'Terms',
}

export const facilitiesAndServicesCategories: Record<
  FacilitiesAndServicesCategories,
  Array<keyof PropertyV2>
> = {
  [FacilitiesAndServicesCategories.FACILITIES]: [
    'attr_concierge',
    'attr_parcel',
    'attr_onsite_maintenance',
    'attr_bike_storage',
    'attr_parking',
    'attr_lift',
    'attr_wifi',
    'attr_roof_terrace',
    'attr_communal_garden',
    'attr_furnished',
    'attr_dining_room',
    'attr_bar',
    'attr_guest_suites',
    'attr_heating',
    'attr_hot_water',
    'attr_epc',
  ],
  [FacilitiesAndServicesCategories.AMENITIES]: [
    'attr_gym',
    'attr_pool',
    'attr_common_area',
    'attr_entertainment_area',
    'attr_cinema',
    'attr_work_area',
    'attr_library',
    'attr_social_events',
    'attr_usb_port',
    'attr_swimming_pool',
  ],
  [FacilitiesAndServicesCategories.SECURITY]: [
    'attr_controlled_access',
    'attr_cctv',
    'attr_smoke_alarm',
    'attr_video_door_entry',
    'attr_onsite_management',
  ],
  [FacilitiesAndServicesCategories.TERMS]: [
    'max_term',
    'min_term',
    'notice_period',
    'break_clause',
    'deposit_term',
    'zero_deposit',
    'short_rent',
  ],
};

/**
 * Given a category, return an array of attributes in that category that are
 * "truthy" (not undefined, not false)
 */
export function getFacilitiesAndServicesCategoryTruthyAttributes(
  category: FacilitiesAndServicesCategories,
  data: PropertyV2
): Array<keyof PropertyV2> {
  return facilitiesAndServicesCategories[category].filter(
    (attribute) => data[attribute]
  );
}
