import React from 'react';
import { Box } from '@material-ui/core';
import Section from '../Common/Section';
import { PropertyV2 } from '../../../../../api/property/types';
import useStyles from './styles';
import {
  FacilitiesAndServicesCategories,
  getFacilitiesAndServicesCategoryTruthyAttributes,
} from './facilitiesAndServicesCategories';
import { getPropertyAttributeLabel } from '../Common/propertyAttributes';
import PropertyAttributesSection from '../Common/PropertyAttributesSection';
import {
  ExpandedDetailsCategories,
  getExpandedDetailsCategoryTruthyAttributes,
} from '../FlatsSection/expandedDetailsCategories';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2;
}

// ========== COMPONENT ========== //

export default function FacilitiesAndServicesSection({ data }: ComponentProps) {
  const classes = useStyles();

  const facilitiesAttributes = getFacilitiesAndServicesCategoryTruthyAttributes(
    FacilitiesAndServicesCategories.FACILITIES,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  const amenitiesAttributes = getFacilitiesAndServicesCategoryTruthyAttributes(
    FacilitiesAndServicesCategories.AMENITIES,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  const securityAttributes = getFacilitiesAndServicesCategoryTruthyAttributes(
    FacilitiesAndServicesCategories.SECURITY,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  const costsAttributes = getFacilitiesAndServicesCategoryTruthyAttributes(
    FacilitiesAndServicesCategories.TERMS,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  const atmosphereAttributes = getExpandedDetailsCategoryTruthyAttributes(
    ExpandedDetailsCategories.ATMOSPHERE,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  return (
    <Section id="facilities-and-services" title="Facilities & services">
      <Box className={classes.ctn}>
        {/* Development facilities */}

        {facilitiesAttributes.length > 0 && (
          <PropertyAttributesSection
            title="Facilities"
            attributes={facilitiesAttributes}
          />
        )}

        {/* Development amenities */}

        {amenitiesAttributes.length > 0 && (
          <PropertyAttributesSection
            title="Amenities"
            attributes={amenitiesAttributes}
          />
        )}

        {/* Security & safety */}

        {securityAttributes.length > 0 && (
          <PropertyAttributesSection
            title="Security & safety"
            attributes={securityAttributes}
          />
        )}

        {/* Atmosphere */}

        {atmosphereAttributes.length > 0 && (
          <PropertyAttributesSection
            title="Atmosphere"
            attributes={atmosphereAttributes}
          />
        )}

        {/* Terms of tenancy */}

        {costsAttributes.length > 0 && (
          <PropertyAttributesSection
            title="Terms of tenancy"
            attributes={costsAttributes}
          />
        )}
      </Box>
    </Section>
  );
}
