import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      marginTop: -theme.spacing(5),
      paddingTop: theme.spacing(8),
      width: '100%',
    },

    loadMapBtnCtn: {
      position: 'relative',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      height: 200,

      overflow: 'hidden',
    },

    loadMapBtnCtnBkg: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.5,
      objectFit: 'cover',
    },

    loadMapBtn: {
      fontSize: '1.25rem',
    },
  })
);

export default useStyles;
