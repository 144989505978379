import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      borderRadius: 5,
      padding: 0,
      border: 'none',
    },
  })
);
