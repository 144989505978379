import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { Player, BigPlayButton } from 'video-react';
import { MediaData } from '../../../api/property/types';

interface ComponentProps {
  video: MediaData | null;
  toggle: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popupContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 50,
      left: '12.5vw',
      zIndex: 1000,
      boxSizing: 'content-box',
      background: '#000',
      height: 'calc(100vh - 128px)',
      width: '75vw',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 190px)',
        zIndex: 2,
        left: 0,
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 180px)',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 150px)',
      },
    },
    player: {
      maxHeight: 'calc(100vh - 128px)',
      width: '100%',
    },
  })
);

export default function PopupVideoPlayer({
  video,
  toggle = false,
}: ComponentProps) {
  const classes: Record<string, string> = useStyles();

  if (!toggle || !video) return null;

  return (
    <div className={classes.popupContainer}>
      {video && (
        <Player
          fluid={false}
          className={classes.player}
          playsInline
          poster={video.src_url}
          src={video.src_video_url}
          autoPlay={false}
          width="100%"
          height="100%"
        >
          <source src={video.src_video_url} />
          <BigPlayButton position="center" />
        </Player>
      )}
    </div>
  );
}
