import * as React from 'react';
import { SvgIconProps } from '@material-ui/core';
import TflRail from '../../images/iconsTfl/TflRail';
import TflTube from '../../images/iconsTfl/TflTube';
import TflDlr from '../../images/iconsTfl/TflDlr';
import TflBus from '../../images/iconsTfl/TflBus';
import { LocalGroceryStore, School, ShoppingBasket } from '@material-ui/icons';
import { NearbyPlaceType } from '../../api/property/types';

export const placeTypeIconMapping: Record<
  NearbyPlaceType,
  React.FunctionComponent<SvgIconProps>
> = {
  [NearbyPlaceType.TRAIN_STATION]: TflRail,
  [NearbyPlaceType.SUBWAY_STATION]: TflTube,
  [NearbyPlaceType.LIGHT_RAIL_STATION]: TflDlr,
  [NearbyPlaceType.BUS_STATION]: TflBus,
  [NearbyPlaceType.SUPERMARKET]: LocalGroceryStore,
  [NearbyPlaceType.STORE]: ShoppingBasket,
  [NearbyPlaceType.SCHOOL]: School,
};

export const placeTypePriorityMapping: Record<NearbyPlaceType, number> = {
  [NearbyPlaceType.TRAIN_STATION]: 10,
  [NearbyPlaceType.SUBWAY_STATION]: 20,
  [NearbyPlaceType.LIGHT_RAIL_STATION]: 30,
  [NearbyPlaceType.BUS_STATION]: 40,
  [NearbyPlaceType.SUPERMARKET]: 50,
  [NearbyPlaceType.STORE]: 60,
  [NearbyPlaceType.SCHOOL]: 70,
};
