import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },

    mainImgCtn: {
      position: 'relative',
      marginBottom: theme.spacing(1),
      width: '100%',
      height: 570,
      border: `1px solid ${theme.palette.grey['400']}`,
      borderRadius: 3,
      '& img': {
        height: 570,
        [theme.breakpoints.down('xs')]: {
          height: '300px',
        },
      },
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        border: 0,
        width: 'auto',
        margin: '-16px -16px 0',
      },
    },

    sideImgsCtn: {
      display: 'grid',
      grid: `auto-flow / repeat(6, 1fr)`,
      gridGap: theme.spacing(1),
      justifyContent: 'space-between',

      marginTop: 20,

      width: '100%',

      [theme.breakpoints.down('sm')]: {
        grid: `auto-flow / repeat(4, 1fr)`,
      },
      [theme.breakpoints.down('xs')]: {
        grid: `auto-flow / repeat(3, 1fr)`,
        display: 'none',
      },

      '& > *': {
        border: `1px solid ${theme.palette.grey['400']}`,
        [theme.breakpoints.down('sm')]: {
          '&:nth-child(3), &:nth-child(4)': {
            display: 'none',
          },
        },
        [theme.breakpoints.down('xs')]: {
          '&:nth-child(2), &:nth-child(3), &:nth-child(4)': {
            display: 'none',
          },
        },
      },
    },

    sideImgCtn: {
      height: 100,
      position: 'relative',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
      cursor: 'pointer',
    },

    lowOpacity: {
      opacity: 0.25,
    },

    prev: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: '0.75',
        padding: '8px 6px 8px 12px',
        alignItems: 'center',
        borderRadius: '5px',
        justifyContent: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        left: 15,
        '& svg': {
          width: 16,
        },
      },
    },

    next: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: '0.75',
        padding: '8px 8px 8px 10px',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '5px',
        justifyContent: 'center',
        backgroundColor: '#fff',
        right: 15,
        '& svg': {
          width: 16,
        },
      },
    },

    imageThumbnail: {
      position: 'relative',
    },

    playIcon: {
      width: 64,
      height: 64,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,0.5)',
      borderRadius: '50%',
      color: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      pointerEvents: 'none',
      '& svg': {
        fontSize: theme.typography.pxToRem(36),
      },
    },

    playThumbnailIcon: {
      width: 36,
      height: 36,
      '& svg': {
        fontSize: theme.typography.pxToRem(24),
      },
    },
  })
);

export default useStyles;
