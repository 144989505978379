import { createStyles, makeStyles, Theme, colors } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      marginBottom: theme.spacing(2),
    },

    sectionCtn: {
      padding: theme.spacing(2),
    },

    promotionSectionCtn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.grey['100'],
      borderWidth: `1px 1px 0 1px`,
      borderColor: colors.grey['300'],
      borderStyle: 'solid',
    },

    promotionSectionTitleCtn: {
      display: 'flex',
      alignItems: 'center',

      // This targets the logo on the title
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
    },

    rentAndFavouriteBtnSectionCtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },

    quickStatsSectionCtn: {
      display: 'flex',
      flexDirection: 'column',
      borderWidth: 1,
      borderColor: colors.grey['300'],
      borderStyle: 'solid',
      [theme.breakpoints.down('sm')]: {
        border: 'none',
        padding: 0,
      },
    },

    cheapestRentAmt: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },

    bedroomsCtn: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
      },
    },

    bedroomsRow: {
      '& div:first-child': {
        marginRight: '5rem',
      },
    },

    bedroomTitle: {
      color: '#808080',
    },

    favoriteBtn: {
      padding: 0,
    },

    propertyTopTags: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1),
      padding: 0,

      '& > li': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 'auto',
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        padding: theme.spacing(0.5, 2),
      },
    },

    popularityBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },

    logoHome: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      background: colors.grey['100'],
      borderRadius: '100%',
    },

    enquireButton: {
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
    },

    claimThisDevelopmentButtonCtn: {
      padding: theme.spacing(2),
      marginTop: '.5rem',
      border: `1px solid ${colors.grey['300']}`,
    },

    claimThisDevelopmentButton: {
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
      textTransform: 'none',
    },

    phoneButton: {
      marginBottom: theme.spacing(1),
      fontSize: '1.25rem',
      fontWeight: 'bold',
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },

    websiteButton: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
    },

    promotionSection2Ctn: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.grey['100'],
      marginTop: theme.spacing(2),
    },

    title: {
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
    },

    colorLight: {
      color: '#B2B2B2',
    },

    dNone: {
      display: 'none',
    },

    showMobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },

    landlordCtn: {
      marginBottom: theme.spacing(1),
    },

    landlordName: {
      padding: theme.spacing(1),
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    landlordLogo: {
      display: 'flex',
      padding: theme.spacing(1),
    },
  })
);
