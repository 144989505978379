/**
 * We use google-map-react to connect with the Google Maps JavaScript SDK.
 * https://github.com/google-map-react/google-map-react
 */

import React, { MutableRefObject, useEffect, useState } from 'react';
import GoogleMapReact, { Props } from 'google-map-react';
import { AppBar, Box, Container, Tab, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import Pin from './Pin';
import { LatLngCoords } from '../../../utils/geolocation';
import useStyles from './styles/Map.styles';
import { School, LocalGroceryStore, ShoppingBasket } from '@material-ui/icons';
import TflBus from '../../../images/iconsTfl/TflBus';
import TflTube from '../../../images/iconsTfl/TflTube';
import TflDlr from '../../../images/iconsTfl/TflDlr';
import TflRail from '../../../images/iconsTfl/TflRail';
import { pluralizeUsingS } from '../../../utils/string';
import { formatNumber, metersToMiles } from '../../../utils/number';
import { NearbyPlace } from '../../../models/nearbyPlaces';

interface MapConfig {
  center: LatLngCoords;
  zoom: number;
  hoverDistance: number;
}

interface PinData {
  lat: number;
  lng: number;
  data?: Record<string, string | undefined>;
}

export interface MapProps {
  mapConfig: MapConfig;
  mapRef: MutableRefObject<any>;
  mapsRef: MutableRefObject<any>;
  pinsData: PinData[];
  nearbyPlaces: NearbyPlace[];
  selfClickHideTab?: boolean;
  // A callback to run side-effects after the map has successfully loaded
  onApiLoaded?: (map: any, maps: any) => void;

  // These are passed to the underlying GoogleMapReact component
  mapProps?: Props;
}

export default function Map({
  mapConfig,
  mapRef,
  mapsRef,
  pinsData,
  onApiLoaded,
  mapProps,
  nearbyPlaces,
  selfClickHideTab,
}: MapProps) {
  const classes = useStyles();

  const arrayNearbyPlacesType: string[] = [
    'train_station',
    'bus_station',
    'supermarket',
    'store',
    'school',
  ];

  const arrayNearbyPlaceTitle = {
    train_station: 'station',
    bus_station: 'bus stop',
    supermarket: 'supermarket',
    store: 'store',
    school: 'school',
  };

  const { center, zoom, hoverDistance } = mapConfig;

  const [showPinsData, setShowPinsData] = useState<PinData[]>([]);
  const [isShowPin, setIsShowPin] = useState<string[]>(['']);
  const [tabType, setTabType] = useState(arrayNearbyPlacesType[0]);

  useEffect(() => {
    setShowPinsData(pinsData);
    handleShowNearbyPlacesType(arrayNearbyPlacesType[0]);
  }, []);

  // We can access Google Maps' map and maps objects via the onGoogleApiLoaded
  // prop.
  // https://github.com/google-map-react/google-map-react#use-google-maps-api
  const handleApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    // Store the Map and Maps objects in their respective React Refs
    mapRef.current = map;
    mapsRef.current = maps;
    if (onApiLoaded) {
      onApiLoaded(map, maps);
    }
  };

  const getNearbyPlacesByPlaceType = (nearbyPlaces: NearbyPlace[]) => {
    return nearbyPlaces.reduce<Record<string, NearbyPlace[]>>(
      (object, item) => {
        if (!object[item.place_type]) {
          object[item.place_type] = [];
        }
        object[item.place_type].push(item);
        return object;
      },
      {}
    );
  };

  const nearbyPlacesByPlaceType = getNearbyPlacesByPlaceType(nearbyPlaces);
  const trainStation = nearbyPlacesByPlaceType['train_station'] ?? [];
  const subwayStation = nearbyPlacesByPlaceType['subway_station'] ?? [];
  const light_railStation = nearbyPlacesByPlaceType['light_rail_station'] ?? [];

  trainStation.push(...subwayStation, ...light_railStation);
  nearbyPlacesByPlaceType['train_station'] = trainStation;

  const handleShowNearbyPlacesType = (type: string) => {
    const pinData: PinData[] = [];
    if (!nearbyPlacesByPlaceType[type])
      return setShowPinsData([...pinData, pinsData[0]]);
    nearbyPlacesByPlaceType[type].map((nearbyPlace) => {
      const location = nearbyPlace.place_data.geometry.location;
      pinData.push({
        lat: location.lat,
        lng: location.lng,
        data: {
          name: nearbyPlace.place_data.name,
          type: nearbyPlace.place_type,
          img: nearbyPlace.place_data.icon,
          distance: String(nearbyPlace.place_distance_m),
        },
      });
    });

    setShowPinsData([...pinData, pinsData[0]]);
  };

  const handleHideNearbyPlacesType = (type: string) => {
    if (!selfClickHideTab) {
      return;
    }

    const positionType = isShowPin.indexOf(type);
    isShowPin.splice(positionType, 1);
    setIsShowPin([...isShowPin]);
    const filter = showPinsData.filter((pinData: PinData) => {
      if (pinData.data) {
        if (type === 'train_station') {
          return (
            pinData.data.type !== 'train_station' &&
            pinData.data.type !== 'subway_station' &&
            pinData.data.type !== 'light_rail_station'
          );
        } else {
          return pinData.data.type !== type;
        }
      }
    });
    setShowPinsData(filter);
  };

  const pins =
    showPinsData.length !== 0 &&
    showPinsData.map((data) => {
      const { lat, lng } = data;
      return (
        <Pin
          key={`${data?.data?.type}:${lat}${lng}:${data?.data?.name}`}
          lat={lat}
          lng={lng}
          name={data?.data && data.data.name}
          img={data?.data && data.data.img}
          type={data?.data && data.data.type}
          distance={data?.data && data.data.distance}
        />
      );
    });

  const handleChange = (event, value: string) => {
    if (value === tabType) {
      setTabType(selfClickHideTab ? '' : tabType);
    } else {
      setTabType(value);
    }
  };

  const iconPlaceType = (placeType: string) => {
    return (
      <>
        {placeType === 'train_station' && (
          <TflTube className={classes.stationIcon} />
        )}
        {placeType === 'bus_station' && (
          <TflBus className={classes.stationIcon} />
        )}
        {placeType === 'supermarket' && (
          <LocalGroceryStore className={classes.storeIcon} />
        )}
        {placeType === 'store' && (
          <ShoppingBasket className={classes.storeIcon} />
        )}
        {placeType === 'school' && <School className={classes.schoolIcon} />}
      </>
    );
  };

  const iconListPlaceType = (nearby: NearbyPlace) => {
    return (
      <>
        {nearby.place_type === 'train_station' && (
          <TflRail className={classes.stationIcon} />
        )}
        {nearby.place_type === 'subway_station' && (
          <TflTube className={classes.stationIcon} />
        )}
        {nearby.place_type === 'light_rail_station' && (
          <TflDlr className={classes.stationIcon} />
        )}
        {nearby.place_type === 'bus_station' && (
          <TflBus className={classes.stationIcon} />
        )}
        {nearby.place_type === 'supermarket' && (
          <LocalGroceryStore className={classes.storeIcon} />
        )}
        {nearby.place_type === 'store' && (
          <ShoppingBasket className={classes.storeIcon} />
        )}
        {nearby.place_type === 'school' && (
          <School className={classes.schoolIcon} />
        )}
      </>
    );
  };

  const titleTab = (placeType: string) => {
    switch (placeType) {
      case 'train_station':
        return 'station';
      case 'bus_station':
        return 'bus';
      case 'store':
        return 'shopping';
      default:
        return placeType.replace('_', ' ');
    }
  };

  const listNearByPlace = () => {
    return (
      <TabContext value={tabType}>
        <AppBar position="static" color="default">
          <TabList
            onChange={handleChange}
            className={clsx(classes.container, classes.iconTab)}
            indicatorColor="primary"
            scrollButtons="on"
            variant="scrollable"
            classes={{ flexContainer: classes.tabContainer }}
          >
            {arrayNearbyPlacesType.map((placeType) => (
              <Tab
                label={titleTab(placeType)}
                icon={iconPlaceType(placeType)}
                value={placeType}
                key={placeType}
                onClick={() => {
                  tabType !== placeType
                    ? handleShowNearbyPlacesType(placeType)
                    : handleHideNearbyPlacesType(placeType);
                }}
              />
            ))}
          </TabList>
        </AppBar>
        {arrayNearbyPlacesType.map((placeType) => (
          <TabPanel
            value={placeType}
            key={placeType}
            className={classes.tabPanel}
          >
            <Container maxWidth="lg">
              <Box className={classes.listmapWrapper}>
                <Typography variant="h4">
                  {pluralizeUsingS(
                    `Nearest ${arrayNearbyPlaceTitle[placeType]}`,
                    nearbyPlacesByPlaceType[placeType]?.length
                  )}
                </Typography>
              </Box>
              <Box className={classes.listInfoWrapper}>
                {Array.isArray(nearbyPlacesByPlaceType[placeType]) &&
                  nearbyPlacesByPlaceType[placeType].map((nearby, index) => {
                    const distance = Math.max(
                      nearby.place_distance_m
                        ? metersToMiles(nearby.place_distance_m)
                        : 0,
                      0.1
                    );
                    const distanceStr = formatNumber(distance, {
                      decimalPlaces: 1,
                    });

                    return (
                      <Box className={classes.nearby} key={index}>
                        {iconListPlaceType(nearby)}
                        <Box className={classes.nearbyDetail}>
                          <Box className={classes.nearbyDetailName}>
                            {nearby.place_data.name}{' '}
                          </Box>
                          <Box className={classes.dataWrapper}>
                            <span>{`${distanceStr} ${
                              distanceStr === '1.0' ? 'mile' : 'miles'
                            }`}</span>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                {!Array.isArray(nearbyPlacesByPlaceType[placeType]) && (
                  <Box className={classes.nearby} mb={1}>
                    <Typography>
                      There are no{' '}
                      {pluralizeUsingS(placeType.split('_').join(' '))} near
                      this development
                    </Typography>
                  </Box>
                )}
                {Array.isArray(nearbyPlacesByPlaceType[placeType]) &&
                  nearbyPlacesByPlaceType[placeType].length === 0 && (
                    <Box className={classes.nearby} mb={1}>
                      <Typography>
                        There are no{' '}
                        {pluralizeUsingS(placeType.split('_').join(' '))} near
                        this development
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Container>
          </TabPanel>
        ))}
      </TabContext>
    );
  };

  // https://github.com/google-map-react/google-map-react/blob/master/API.md#options-funcobject
  const mapOptions = {
    // https://developers.google.com/maps/documentation/javascript/controls#Adding_Controls_to_the_Map
    // Also note that Street View panoramas generated via this Street View
    // Pegman control are not billed, but Street View panoramas created with
    // the StreetViewPanorama object are billed.
    // https://developers.google.com/maps/documentation/javascript/streetview#StreetViewPanoramas
    // https://developers.google.com/maps/documentation/javascript/usage-and-billing#dynamic-street-view
    streetViewControl: true,
  };

  return (
    <Box>
      <Box className={classes.ctn}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? '',
            libraries: ['places'],
            language: 'en-GB',
            region: 'GB',
          }}
          center={center}
          zoom={zoom}
          hoverDistance={hoverDistance}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
          options={mapOptions}
          {...mapProps}
        >
          {pins}
        </GoogleMapReact>
      </Box>
      <Box className={classes.listInfoTab}>
        {arrayNearbyPlacesType.length && listNearByPlace}
      </Box>
    </Box>
  );
}
