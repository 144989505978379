import * as React from 'react';
import { SvgIconProps, Typography } from '@material-ui/core';
import PropertySummaryTextRow from './PropertySummaryTextRow';
import { getNearbyPlacesText } from '../../../../../utils/property';
import { NearbyPlace } from '../../../../../models/nearbyPlaces';

// ========== TYPES ========== //

export interface NearbyPlacesSubSectioProps {
  placeIcon: React.FunctionComponent<SvgIconProps>;
  places: NearbyPlace[];
}

// ========== COMPONENT ========== //

export default function NearbyPlacesSubSection({
  placeIcon: PlaceIcon,
  places,
}: NearbyPlacesSubSectioProps) {
  return (
    <PropertySummaryTextRow icon={PlaceIcon}>
      <Typography>
        {getNearbyPlacesText(places, { distanceUnit: 'miles' })}
      </Typography>
    </PropertySummaryTextRow>
  );
}
