import {
  gtmDataLayerPSVAreaGuideEconomyPush,
  gtmDataLayerPSVAreaGuideExploreNearByPush,
  gtmDataLayerPSVAreaGuideFoodPush,
  gtmDataLayerPSVAreaGuideMovingToPush,
  gtmDataLayerPSVAreaGuideNightLifePush,
  gtmDataLayerPSVAreaGuidePopularAttractionPush,
  gtmDataLayerPSVAreaGuideShoppingPush,
  gtmDataLayerPSVAreaGuideTravelPush,
} from '../../../../../utils/marketing/eventPushes/gtmDataLayerPSV';

export default function handleAreaGuideTitleClick(
  areaGuideTitle: string,
  developmentId: number
): void {
  const title = areaGuideTitle.replace(/ /g, '-').toLowerCase();

  if (title.includes('moving-to')) {
    return gtmDataLayerPSVAreaGuideMovingToPush({ developmentId });
  }

  if (title.includes('economy')) {
    return gtmDataLayerPSVAreaGuideEconomyPush({ developmentId });
  }

  if (title.includes('popular-attraction')) {
    return gtmDataLayerPSVAreaGuidePopularAttractionPush({ developmentId });
  }

  if (title.includes('nightlife-in')) {
    return gtmDataLayerPSVAreaGuideNightLifePush({ developmentId });
  }

  if (title.includes('food-in')) {
    return gtmDataLayerPSVAreaGuideFoodPush({ developmentId });
  }

  if (title.includes('shopping-in')) {
    return gtmDataLayerPSVAreaGuideShoppingPush({ developmentId });
  }

  if (title.includes('travel-in')) {
    return gtmDataLayerPSVAreaGuideTravelPush({ developmentId });
  }

  if (title.includes('explore-nearby')) {
    return gtmDataLayerPSVAreaGuideExploreNearByPush({ developmentId });
  }
}
