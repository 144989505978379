import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import NearbyPlacesSubSection from './NearbyPlacesSubSection';
import { getNearbyPlacesByPlaceType } from '../../../../../utils/address';
import {
  placeTypeIconMapping,
  placeTypePriorityMapping,
} from '../../../../../config/places';
import { NearbyPlace } from '../../../../../models/nearbyPlaces';
import { NearbyPlaceType } from '../../../../../api/property/types';

// ========== TYPES ========== //

export interface NearestPlacesSectionProps {
  nearbyPlaces: NearbyPlace[];
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
    },

    content: {
      paddingTop: theme.spacing(1),
    },
  })
);

// ========== COMPONENT ========== //

function sortPlaceTypes(a: NearbyPlaceType, b: NearbyPlaceType): number {
  return placeTypePriorityMapping[a] - placeTypePriorityMapping[b];
}

export default function NearestPlacesSection({
  nearbyPlaces,
}: NearestPlacesSectionProps) {
  const classes = useStyles();

  const nearbyPlacesByPlaceType = getNearbyPlacesByPlaceType(nearbyPlaces);
  const placeTypes = Object.keys(nearbyPlacesByPlaceType).sort(sortPlaceTypes);

  return (
    <Box className={classes.ctn}>
      {placeTypes.map((placeType) => (
        <Box key={placeType} className={classes.content}>
          <NearbyPlacesSubSection
            placeIcon={placeTypeIconMapping[placeType]}
            places={nearbyPlacesByPlaceType[placeType].slice(0, 3)}
          />
        </Box>
      ))}
    </Box>
  );
}
