import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    virtualTour: {
      fontSize: 20,
      color: theme.palette.gray.dark,
      padding: '80px 15px 80px',
      background: '#f3f3f3',
    },
    container: {
      maxWidth: theme.custom.container1460,
      padding: 0,
    },
    title: {
      fontSize: theme.spacing(4),
      color: theme.palette.gray.dark,
      textTransform: 'uppercase',
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: 30,
      },
    },
  })
);
