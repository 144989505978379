export interface GetEnquiryFormEmailToConfig {
  organizationId: number;
  contactEmail: string | undefined;
}

export default function getEnquiryFormEmailTo({
  organizationId,
  contactEmail,
}: GetEnquiryFormEmailToConfig): string {
  if (!contactEmail) {
    return '';
  }

  // TODO: temporary measure to avoid spamming landlords
  if (organizationId === 1) {
    return 'info@buildtorent.io';
  }

  return contactEmail;
}
