import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    space: {
      paddingRight: theme.spacing(0.5),
    },
  })
);

export default function LongSpace() {
  const classes = useStyles();

  return (
    <span className={classes.space} data-testid="long-space">
      {' '}
    </span>
  );
}
