import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    billsCtn: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
      gap: theme.spacing(1),

      padding: theme.spacing(2),

      backgroundColor: `rgba(98, 196, 179, 0.1)`,
      border: `1px solid ${theme.palette.teal.main}`,
      borderRadius: 5,
    },

    billCtn: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
  })
);
