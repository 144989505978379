import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      position: 'fixed',
      top: 0,
      opacity: 0,
      pointerEvents: 'none',
      zIndex: 1100,
      background: '#ffffff',
      width: '100%',
      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      '& ul': {
        display: 'flex',
        alignItems: 'center',
        '& li': {
          width: 'auto',
        },
      },
      '& a': {
        color: `${theme.palette.gray.dark} !important`,
      },
      height: 83,
    },

    active: {
      opacity: 1,
      pointerEvents: 'initial',
    },

    container: {
      maxWidth: 1280,
      margin: 'auto',
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
    },

    scrollTop: {
      paddingRight: 7,
      cursor: 'pointer',
      color: theme.palette.gray.dark,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& span': {
        marginTop: -8,
      },
    },

    activeHeader: {
      '& a': {
        color: `${theme.palette.primary.main} !important`,
      },
    },

    propertiesRight: {
      display: 'flex',
      alignItems: 'center',
      '& a': {
        background: 'red',
        color: '#ffffff !important',
        display: 'flex',
        height: '52px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 72px',
        textTransform: 'capitalize',
        marginRight: '15px',
      },
    },

    disableSelectRoom: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      background: '#63656a !important',
    },

    dNone: {
      display: 'none',
    },
  })
);
