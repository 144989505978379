import * as React from 'react';
import { Box, List, ListItem } from '@material-ui/core';
import { ArrowDropUp } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';
import { Link } from '../../../../atoms';
import { PropertyV2 } from '../../../../../api/property/types';

interface ComponentProps {
  data: PropertyV2[];
  hideItems?: string[];
}

export default function HeaderStickyProperty({
  data,
  hideItems,
}: ComponentProps) {
  const classes = useStyles();

  const [isShowSticky, setIsShowSticky] = React.useState(false);
  const [active, setActive] = React.useState('facilities');

  React.useEffect(() => {
    const propertyDetails = document.getElementById('property-details');
    window.onscroll = () => {
      const showStickyOffset = propertyDetails
        ? propertyDetails.offsetTop + propertyDetails.clientHeight - 40
        : 2000;

      checkActiveHeaderSticky(window);
      if (window.pageYOffset > showStickyOffset) {
        setIsShowSticky(true);
      } else {
        setIsShowSticky(false);
      }
    };
  }, [data]);

  const checkActiveHeaderSticky = (window: Window) => {
    const facilities: HTMLElement | null = document.getElementById(
      'facilities-and-services'
    );
    const available: HTMLElement | null = document.getElementById(
      'available-properties'
    );
    const bills: HTMLElement | null = document.getElementById('your-bills');
    const map: HTMLElement | null = document.getElementById('map-section');
    const areaGuide: HTMLElement | null = document.getElementById('area-guide');
    const virtual: HTMLElement | null = document.getElementById('virtual-tour');
    const similar: HTMLElement | null =
      document.getElementById('similar-properties');
    if (available && window.pageYOffset >= available.offsetTop) {
      setActive('available');
    }
    if (facilities && window.pageYOffset >= facilities.offsetTop) {
      setActive('facilities');
    }
    if (bills && window.pageYOffset >= bills.offsetTop) {
      setActive('bills');
    }
    if (map && window.pageYOffset >= map.offsetTop) {
      setActive('map');
    }
    if (areaGuide && window.pageYOffset >= areaGuide.offsetTop) {
      setActive('areaGuide');
    }
    if (virtual && window.pageYOffset >= virtual.offsetTop) {
      setActive('virtual');
    }
    if (similar && window.pageYOffset >= similar.offsetTop) {
      setActive('similar');
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box className={clsx(classes.sticky, isShowSticky && classes.active)}>
      <Box className={classes.container}>
        <List>
          <ListItem
            className={clsx(active === 'available' && classes.activeHeader)}
          >
            <Link to="#available-properties">Available properties</Link>
          </ListItem>
          <ListItem
            className={clsx(active === 'facilities' && classes.activeHeader)}
          >
            <Link to="#facilities-and-services">Facilities & services</Link>
          </ListItem>
          {!hideItems?.includes('bills') && (
            <ListItem
              className={clsx(active === 'bills' && classes.activeHeader)}
            >
              <Link to="#your-bills">Your bills</Link>
            </ListItem>
          )}
          <ListItem className={clsx(active === 'map' && classes.activeHeader)}>
            <Link to="#map-section">Map</Link>
          </ListItem>
          <ListItem
            className={clsx(active === 'areaGuide' && classes.activeHeader)}
          >
            <Link to="#area-guide">Area Guide</Link>
          </ListItem>

          {/* TODO: re-enable when virtual tour data is available */}
          {/*<ListItem*/}
          {/*  className={clsx(active === 'virtual' && classes.activeHeader)}*/}
          {/*>*/}
          {/*  <Link to="#virtual-tour">Virtual tour</Link>*/}
          {/*</ListItem>*/}

          <ListItem
            className={clsx(active === 'similar' && classes.activeHeader)}
          >
            <Link to="#similar-properties">Similar properties</Link>
          </ListItem>
        </List>
        <Box className={classes.propertiesRight}>
          <Link
            to="#available-properties"
            className={clsx(
              active === 'available' && classes.disableSelectRoom
            )}
          >
            Select Your Property
          </Link>
          <Box className={classes.scrollTop} onClick={scrollTop}>
            <ArrowDropUp />
            <span>Top</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
