import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function TflBus(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 615.3 500"
      enableBackground="new 0 0 615.3 500"
      {...props}
    >
      <g>
        <path
          fill="#DC241F"
          d="M469.4,250c0,89.1-72.3,161.3-161.3,161.3c-89.1,0-161.3-72.2-161.3-161.3c0-89.1,72.2-161.3,161.3-161.3   C397.2,88.7,469.4,160.9,469.4,250 M308.1,0C170,0,58.1,111.9,58.1,250c0,138.1,111.9,250,250,250c138.1,0,250-111.9,250-250   C558.1,111.9,446.2,0,308.1,0"
        />
        <rect y="199.5" fill="#DC241F" width="615.3" height="101.1" />
        <path
          fill="#FFFFFF"
          d="M217.4,276.1h-17.9V224h15.3c10.5,0,16.3,5.1,16.3,13c0,5-2.7,8.9-7.3,10.4c6.9,1.2,11.2,6.4,11.2,13.7   C235,268.2,229.9,276.1,217.4,276.1 M214.5,232.8h-4.9v11.5h4.6c4.2,0,6.8-2.2,6.8-5.7C221,235.1,218.6,232.8,214.5,232.8    M217.1,253.1h-7.6v14.1h6.8c5.2,0,8.4-2.4,8.4-6.8C224.7,255.2,221,253.1,217.1,253.1"
        />
        <path
          fill="#FFFFFF"
          d="M285.7,268.6c-4.2,5.2-10.5,8.5-18.3,8.5c-7.8,0-14.1-3.2-18.4-8.6c-3.4-4.1-4.9-9-4.9-16V224h10.1v28.6   c0,8.9,5.2,14.8,13.1,14.8c8.2,0,13.2-5.9,13.2-14.8V224h10.1v28.2C290.7,259.4,289.4,264.1,285.7,268.6"
        />
        <path
          fill="#FFFFFF"
          d="M315.6,277.1c-8.7,0-15.3-5.4-17.8-11l8.7-4.9c2.3,4.5,5.3,6.6,9.7,6.6c4.1,0,6.6-2.9,6.6-6.6   c0-3.4-2-5.5-7.4-8.3l-6.2-3.2c-5.9-3-8.7-7.8-8.7-13c0-8.1,6.8-13.6,15.4-13.6c7.3,0,12.5,3.4,14.8,9l-8.5,4.6   c-1.3-2.7-3.2-4.6-6.5-4.6c-3.3,0-5.4,1.8-5.4,4.4c0,2.9,2.3,4.2,6.9,6.4l5.8,2.8c6.6,3.2,9.8,8.4,9.8,15.3   C332.7,270.8,325.9,277.1,315.6,277.1"
        />
        <polygon
          fill="#FFFFFF"
          points="341.5,276.1 341.5,224 373.8,224 373.8,233.1 351.6,233.1 351.6,243.9 369.9,243.9 369.9,253.1    351.6,253.1 351.6,266.8 375.7,266.8 375.7,276.1  "
        />
        <path
          fill="#FFFFFF"
          d="M399.1,277.1c-8.7,0-15.3-5.4-17.8-11l8.7-4.9c2.3,4.5,5.3,6.6,9.7,6.6c4.1,0,6.6-2.9,6.6-6.6   c0-3.4-2-5.5-7.4-8.3l-6.2-3.2c-5.8-3-8.7-7.8-8.7-13c0-8.1,6.8-13.6,15.4-13.6c7.3,0,12.5,3.4,14.8,9l-8.5,4.6   c-1.3-2.7-3.2-4.6-6.5-4.6c-3.3,0-5.4,1.8-5.4,4.4c0,2.9,2.3,4.2,6.9,6.4l5.8,2.8c6.6,3.2,9.8,8.4,9.8,15.3   C416.2,270.8,409.4,277.1,399.1,277.1"
        />
      </g>
    </SvgIcon>
  );
}
