import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      width: '100%',
      height: 450,
      position: 'relative',
    },
    listMapTab: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      '& li': {
        width: 'auto',
        '& button': {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          fontSize: '1rem',
          padding: '8px 20px',
          borderRadius: 4,
          textDecoration: 'none !important',
          textTransform: 'uppercase',
          display: 'flex',
          alignItems: 'center',
          '&.active': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '& img': {
              filter:
                'invert(91%) sepia(98%) saturate(0) hue-rotate(152deg) brightness(106%) contrast(347%)',
            },
          },
          '& img': {
            width: 18,
            marginRight: 8,
          },
          '&:hover': {
            background: 'red',
            color: theme.palette.primary.contrastText,
            '& img': {
              filter:
                'invert(91%) sepia(98%) saturate(0) hue-rotate(152deg) brightness(106%) contrast(347%)',
            },
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        '& button': {
          width: 160,
        },
      },
    },
    listMap: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 8,
      },
      '& li': {
        width: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        '& button': {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          fontSize: '1rem',
          padding: '8px 20px',
          borderRadius: 4,
          textDecoration: 'none !important',
          textTransform: 'uppercase',
          display: 'flex',
          alignItems: 'center',
          '& img': {
            width: 18,
            marginRight: 8,
          },
          '& svg': {
            marginRight: 10,
          },
          [theme.breakpoints.down('xs')]: {
            fontSize: '0.875rem',
            padding: '4px 4px',
          },
        },
      },
    },
    listmapWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
      '& h4': {
        textTransform: 'uppercase',
      },
    },
    nearby: {
      display: 'flex',
      paddingTop: 10,
      width: '50%',
      '& span': {
        display: 'inline-flex',
        marginLeft: 15,
      },
      '& img': {
        width: 18,
        marginRight: 8,
        objectFit: 'contain',
      },
      [theme.breakpoints.down('md')]: {
        width: '62.5%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '50%',
        fontSize: '0.875rem',
        paddingTop: 15,
        paddingLeft: 5,
        paddingRight: 5,
        '& img': {
          width: 16,
        },
        '& span': {
          display: 'inline-flex',
          alignItems: 'center',
          marginRight: 10,
          marginLeft: 0,
        },
        '& svg': {
          fontSize: '0.875rem',
        },
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      padding: 0,
      margin: 'auto',

      '& h4': {
        fontSize: '1.5rem',
        color: theme.palette.gray.dark,
        textTransform: 'uppercase',
        marginBottom: 10,
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
          fontSize: '1rem',
          margin: 0,
        },
      },
    },
    listInfoTab: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    listInfoWrapper: {
      maxHeight: 200,
      overflowY: 'auto',
      display: 'grid',
      columnGap: theme.spacing(4),
      gridTemplateColumns: 'auto auto',
      [theme.breakpoints.down('xs')]: {
        maxHeight: 265,
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: -5,
        marginRight: -5,
        columnGap: 0,
      },
    },
    iconTab: {
      width: '100%',
      padding: '0px',
      '& .MuiTabs-fixed': {
        overflowX: 'scroll !important',
      },
      '& .MuiTabs-centered': {
        justifyContent: 'flex-start',
      },
      '& button': {
        width: 128,
      },
      '& img': {
        width: 18,
      },
    },
    nearbyDetail: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 10,
      fontSize: '1rem',
      flex: 1,
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginTop: -3,
        fontSize: '0.875rem',
      },
    },
    nearbyDetailName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingLeft: 2,
    },
    stationIcon: {
      color: '#3258A8',
    },
    storeIcon: {
      color: theme.palette.secondary.main,
    },
    schoolIcon: {
      color: theme.palette.secondary.main,
    },
    dataWrapper: {
      whiteSpace: 'nowrap',
    },
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    tabPanel: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);
