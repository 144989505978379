import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { BrokenImage } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyImg: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      height: '100%',

      backgroundColor: theme.palette.grey['300'],
    },
  })
);

export default function EmptyImage() {
  const classes = useStyles();

  return (
    <Box className={classes.emptyImg}>
      <BrokenImage />
    </Box>
  );
}
