import { PropertyV2 } from './types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { NearbyPlace } from '../../models/nearbyPlaces';

export interface FetchPropertySuccessResponse {
  data: PropertyV2[];
  meta: {
    developmentId: number;
    flatIds: number[];
    bills: [];
    count: number;
    nearbyPlaces: NearbyPlace[];
    developmentDetails: string;
  };
}

export async function fetchProperty(
  url: string
): Promise<FetchPropertySuccessResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(
      `This property either no longer exists or is private. Please click the button below to go back to the homepage`
    );
  }

  return res.json();
}

interface UsePropertyProps {
  developmentId: number;

  queryConfig?: UseQueryOptions<any>;
}

export default function useProperty({
  developmentId,
  queryConfig,
}: UsePropertyProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/property/${developmentId}`;

  return useQuery<FetchPropertySuccessResponse, Error>(
    [url],
    () => fetchProperty(url),
    queryConfig
  );
}
