import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    // ----- Overall layout ----- //

    card: {
      position: 'relative',
      width: '100%',
    },

    cardContent: {
      // For small screen sizes, we display 1 column with multiple rows
      // For large screen sizes, we display 2 rows. The first row is split
      // between the image carousels and the summary details. The second row is
      // the expanded details.
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        // The card content contains 3 parts:
        // - The image carousels
        // - The summary details
        // - The expanded details
        gridTemplate: `auto / 1fr 1fr`,
      },

      gap: theme.spacing(2),
    },

    // Part 1 of card content
    imgCarouselsCtn: {
      position: 'relative',
      // For small screen sizes, we display 2 columns of equal width
      // For large screen sizes, we display 1 column and 2 rows of equal
      // height
      display: 'flex',
    },

    // Part 2 of card content
    summaryDetailsCtn: {
      gridColumnStart: 2,
      gridColumnEnd: 3,
    },

    // Part 3 of card content
    expandedDetailsCtn: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },

    // ----- Other details ----- //

    imgCtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      marginRight: theme.spacing(5),
      minWidth: 128,
      width: 196,
      height: 80,
      cursor: 'pointer',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(2),
      },
    },

    title: {
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    photoIcon: {
      position: 'absolute',
      bottom: 0,
      right: '5px',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
    },

    iconSize: {
      fontSize: '1rem',
    },

    emptyImgCtn: {
      backgroundColor: theme.palette.grey['400'],
      width: 196,
      display: 'initial',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    },

    rentAmt: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },

    facilitiesRow: {
      fontSize: '.75rem',
    },

    detailsTagsCtn: {
      // display: 'flex',
    },

    detailsTagCtn: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },

    detailsTagIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      fontSize: '1rem',
      color: theme.palette.grey['600'],
    },

    detailsTagText: {
      fontSize: '1rem',
      color: theme.palette.grey['800'],
    },

    section2: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },

    descriptionsCtn: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid #cccccc',
      marginTop: 10,
      paddingTop: 30,
      marginRight: theme.spacing(1),

      '& > *:not(last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    select: {
      fontSize: '1.25rem',
      color: `${theme.palette.primary.contrastText} !important`,
      background: theme.palette.primary.main,
      display: 'inline-block',
      padding: '13px 41px',
      textDecoration: 'auto',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        padding: '8px 30px',
      },
    },
    description: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& p': {
        fontSize: '1.25rem',
        marginRight: 15,
        [theme.breakpoints.down('sm')]: {
          fontSize: '1rem',
        },
        '& span': {
          color: theme.palette.primary.main,
          fontSize: '1.875rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
        },
      },
    },

    descriptionIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },

    bookNowBtnCtn: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap',
    },

    floorPlanImgCtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      width: theme.spacing(50),
      height: theme.spacing(50),

      backgroundColor: theme.palette.grey['400'],
    },

    specialBoxIcon: {
      width: 24,
      height: 24,
    },
  })
);
