import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      alignItems: 'center',
    },

    icon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },

    text: {
      lineHeight: 1.25,
    },
  })
);
