import * as React from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import { PropertyV2 } from '../../../../../api/property/types';
import ExpandedDetails from './ExpandedDetails';
import useFetchFlatImages from '../../../../../api/property/useFetchFlatImages';
import FlatImageCarousel from './FlatImageCarousel';
import useStyles from './styles/FlatCard';
import FlatSummaryDetails from './FlatSummaryDetails';
import {
  createEnquireMailToBody,
  EnquireFormDialog,
} from '../../../../organisms';
import { OrgFileCategory } from '../../../../../api/orgs/types';
import useFetchPropertyByDevelopmentAndFlatId from '../../../../../api/property/useFetchPropertyByDevelopmentAndFlatId';
import gtmDataLayerPSV from '../../../../../utils/marketing/eventPushes/gtmDataLayerPSV';
import splitFlatImagesByCategory from './splitFlatImagesByCategory';
import { CloudinaryPictureSizeConfig } from '../../../../atoms/CloudinaryPicture/CloudinaryPicture';
import getEnquiryFormEmailTo from '../utils/getEnquiryFormEmailTo';
import { bedroomTypes } from '../../../../organisms/EnquireFormDialog/EnquireFormDialog';
import { useSnackbar } from '../../../../../hooks';
import { Snackbar, SnackbarType } from '../../../../molecules';
import { useSendEmailToUs } from '../../../../../api/contact';
import isDevelopmentClaimed from '../utils/isDevelopmentClaimed';
import { setItemInLocalStorage } from '../../../../../utils/localStorage';
import { LOCAL_STORAGE_NAME } from '../../../../../config/localStorage';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2;
}

// ========== COMPONENT ========== //

export default function FlatCard({ data }: ComponentProps) {
  const classes = useStyles();

  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false);

  const [enquireFormDialogOpen, setEnquireFormDialogOpen] =
    React.useState(false);

  const { mutate: sendEmailToUs, status: sendEmailToUsStatus } =
    useSendEmailToUs();

  const { displaySnackbar, hideSnackbar, snackbarState } = useSnackbar();

  React.useEffect(() => {
    if (sendEmailToUsStatus === 'success') {
      displaySnackbar(
        SnackbarType.SUCCESS,
        'Thanks! We have received your query.'
      );
    } else if (sendEmailToUsStatus === 'error') {
      displaySnackbar(
        SnackbarType.ERROR,
        'Sorry, something went wrong. If this persists please email us at info@buildtorent.io'
      );
    }
  }, [sendEmailToUsStatus]);

  const developmentClaimed = isDevelopmentClaimed(data.organization_id);

  const enquireFormDialogEmailTo = getEnquiryFormEmailTo({
    organizationId: data.organization_id,
    contactEmail: data.contact_email,
  });

  const { data: flatImagesData } = useFetchFlatImages({
    developmentId: data.development_id,
    flatId: data.flat_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  const { data: flatData } = useFetchPropertyByDevelopmentAndFlatId({
    developmentId: data.development_id,
    flatId: data.flat_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  const handleEnquireButtonClick = () => {
    setEnquireFormDialogOpen(true);
    gtmDataLayerPSV({
      developmentId: undefined,
      developmentTitle: undefined,
      flatId: data.flat_id,
      flatTitle: data.flat_title,
      flatCategory: data.flat_category,
      flatRentPcm: data.rent_pcm,
    });
  };

  const flatImagesByCategory = splitFlatImagesByCategory(
    flatImagesData?.data ?? [],
    flatData?.data?.flat_img_thumbnail_id
  );

  const thumbnailFlatImage =
    flatImagesByCategory[OrgFileCategory.FLAT_IMAGE]?.[0]?.src_url;
  const thumbnailFloorImage =
    flatImagesByCategory[OrgFileCategory.FLOOR_PLAN_IMAGE]?.[0]?.src_url;

  // The size of each flat thumbnail image will be different depending on
  // whether there is a flat thumbnail alongside a flat floor plan or not.
  const flatImageConfig: CloudinaryPictureSizeConfig =
    thumbnailFlatImage && thumbnailFloorImage
      ? {
          '0': {
            width: 150,
            height: 150,
          },
          '480': {
            width: 300,
            height: 150,
          },
          '768': {
            width: 400,
            height: 200,
          },
        }
      : {
          '0': {
            width: 300,
            height: 300,
          },
          '480': {
            width: 600,
            height: 300,
          },
          '768': {
            width: 800,
            height: 400,
          },
        };

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {/* IMAGE CAROUSELS */}
          {/* Flat images + floor plan images */}

          <Box className={classes.imgCarouselsCtn}>
            <FlatImageCarousel
              property={data}
              flatImages={flatImagesData?.data ?? []}
              thumbnailImage={thumbnailFlatImage}
              category={OrgFileCategory.FLAT_IMAGE}
              config={flatImageConfig}
            />
            <FlatImageCarousel
              property={data}
              flatImages={flatImagesData?.data ?? []}
              thumbnailImage={thumbnailFloorImage}
              category={OrgFileCategory.FLOOR_PLAN_IMAGE}
              config={flatImageConfig}
            />
          </Box>

          {/* SUMMARY DETAILS */}
          {/* Details to summary the flat */}

          <Box className={classes.summaryDetailsCtn}>
            <FlatSummaryDetails
              flatTitle={data.flat_title}
              flatCategory={data.flat_category}
              bedroomsCount={data.bedrooms_count}
              bathroomType={data.attr_bathroom_type ?? ''}
              furnishingOptions={data.attr_furnished ?? ''}
              rent={data.rent_pcm}
              rentCurrency="GBP"
              rentUnit="/month"
              isDetailsExpanded={isDetailsExpanded}
              handleToggleExpandedDetails={() =>
                setIsDetailsExpanded((prev) => !prev)
              }
              handleSelectProperty={handleEnquireButtonClick}
              property={data}
            />

            {enquireFormDialogOpen && (
              <EnquireFormDialog
                open={enquireFormDialogOpen}
                handleClose={() => {
                  setEnquireFormDialogOpen(false);
                }}
                handleSubmit={({
                  name,
                  email,
                  bedroom,
                  moveInDate,
                  phone,
                  numberOfTenants,
                }) => {
                  if (!developmentClaimed) {
                    const { subject, bodyText } = createEnquireMailToBody({
                      developmentTitle: data.title,
                      developmentAddress: data.address ?? '',
                      developmentArea: data.area ?? '',
                      name,
                      email,
                      bedroom,
                      moveInDate,
                      phone,
                      numberOfTenants,
                    });
                    sendEmailToUs({
                      subject,
                      text: bodyText,
                    });
                  }

                  setItemInLocalStorage({
                    key: LOCAL_STORAGE_NAME.ENQUIRE_FORM_TENANT_DATA,
                    value: JSON.stringify({
                      name,
                      email,
                      bedroom,
                      moveInDate,
                      phone,
                      numberOfTenants,
                    }),
                  });

                  setEnquireFormDialogOpen(false);
                }}
                openEmailClientOnSubmit={developmentClaimed}
                developmentTitle={data.title}
                developmentAddress={data.address ?? ''}
                developmentArea={data.area ?? ''}
                emailTo={enquireFormDialogEmailTo}
                initialTenantData={{
                  bedroom: bedroomTypes[data.bedrooms_count] ?? '',
                }}
                flatId={data.flat_id}
              />
            )}
          </Box>

          {/* EXPANDED DETAILS */}
          {/* Detailed information on the flat */}

          <Box className={classes.expandedDetailsCtn}>
            <ExpandedDetails data={data} isExpanded={isDetailsExpanded} />
          </Box>
        </CardContent>
      </Card>

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        {snackbarState.content}
      </Snackbar>
    </>
  );
}
