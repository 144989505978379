import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: theme.custom.container1460,
      margin: 'auto',
      padding: '40px 15px',
      background: '#f3f3f3',
    },
    root: {
      flexGrow: 1,
      display: 'flex',
    },
    explore: {
      marginTop: -70,
      paddingTop: 70,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    tabs: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      color: '#000',
      fontSize: '1.125rem',
      zIndex: 0,
      width: 300,
      '& .MuiTabs-vertical': {
        position: 'relative',
        '&:before': {
          position: 'absolute',
          top: 0,
          right: -1,
          bottom: 0,
          width: '3px',
          backgroundColor: '#e7e7e7',
          content: "''",
        },
      },
      '& .MuiTabs-indicator': {
        background: theme.palette.primary.main,
      },

      '& button': {
        padding: 0,
        paddingRight: 20,
        textTransform: 'capitalize',
        minWidth: '100%',
        textAlign: 'right',
        marginRight: 25,
        '& span': {
          alignItems: 'flex-end',
          lineHeight: '30px',
          fontSize: '1rem',
        },
      },
      '& .Mui-selected': {
        fontWeight: 'bold',
      },
    },
    tab: {
      color: '#000',
    },
    tabContent: {
      paddingLeft: theme.spacing(5),
      color: '#000',

      fontSize: '1rem',

      '& h1, h2, h3, h4, h5, h6': {
        fontSize: '1rem',
      },

      '& > div': {
        padding: 0,
      },
    },
  })
);
