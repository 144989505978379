import { colors, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimThisDevelopmentCtn: {
      marginTop: theme.spacing(1),

      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      border: `1px solid ${colors.grey['300']}`,
    },

    managerAndOwnerSectionCtn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    claimThisDevelopmentButton: {
      fontSize: theme.typography.pxToRem(16),
    },

    logoAndNamePairCtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(0.5),
      width: '100%',
    },

    logoImg: {
      maxWidth: 90,
    },
  })
);

export default useStyles;
