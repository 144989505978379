import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'grid',
      grid: '1fr / 7fr 3fr',
      gap: theme.spacing(2),
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'initial',
      },
    },

    flatCardsCtn: {
      display: 'grid',
      gap: theme.spacing(2),
      width: '100%',
    },

    flatDisclaimerCtn: {
      paddingTop: theme.spacing(2),
    },
  })
);
