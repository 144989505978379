/**
 * Stuff related to property attributes
 */

import { PropertyV2 } from './types';
import { PropertyAttributeGroup } from '../../components/pages/properties/single-view/utils';
import { lodashCaseToNormalCase } from '../../utils/string';

export const propertyAttributeNamesByGroup: Record<
  PropertyAttributeGroup,
  Array<keyof PropertyV2>
> = {
  [PropertyAttributeGroup.DEVELOPMENT_FACILITIES]: [
    'attr_concierge',
    'attr_parcel',
    'attr_onsite_maintenance',
    'attr_bike_storage',
    'attr_parking',
    'attr_lift',
    'attr_wifi',
    'attr_roof_terrace',
    'attr_communal_garden',
    'attr_furnished',
    'attr_dining_room',
    'attr_bar',
    'attr_guest_suites',
    'attr_heating',
    'attr_hot_water',
    'attr_epc',
  ],
  [PropertyAttributeGroup.DEVELOPMENT_AMENITIES]: [
    'attr_gym',
    'attr_pool',
    'attr_common_area',
    'attr_entertainment_area',
    'attr_cinema',
    'attr_work_area',
    'attr_library',
    'attr_social_events',
    'attr_usb_port',
    'attr_swimming_pool',
  ],
  [PropertyAttributeGroup.DEVELOPMENT_ATMOSPHERE]: [
    'fttr_student_friendly',
    'fttr_family_friendly',
    'fttr_pet_friendly',
  ],
  [PropertyAttributeGroup.DEVELOPMENT_SECURITY]: [
    'attr_controlled_access',
    'attr_cctv',
    'attr_smoke_alarm',
    'attr_video_door_entry',
    'attr_onsite_management',
  ],
};

interface GetPropertyAttributesByGroupOptions {
  limit?: number; // Limit how many attributes to return
}

export function countPropertyAvailableAttributesByGroup(
  propertyData: PropertyV2,
  attributeGroup: PropertyAttributeGroup
): number {
  const attributeNames = propertyAttributeNamesByGroup[attributeGroup];

  let total = 0;
  for (const attributeName of attributeNames) {
    if (propertyData[attributeName]) {
      total += 1;
    }
  }

  return total;
}

/**
 * Attribute raw names have the regex /^[af]ttr_[\w_]+$/ e.g. 'attr_gym' or
 * 'fttr_family_friendly'. This function removes the 'attr' / 'fttr' prefix and
 * converts the remaining lodash-cased name to normal case.
 */
export function getPropertyAttributeProperName(rawName: string): string {
  return lodashCaseToNormalCase(rawName.replace(/[af]ttr_/, ''));
}

/**
 * Return attributes available to the property by attribute group in an array.
 *
 * The underlying data for property attributes can be either:
 * - undefined,
 * - Boolean, or
 * - string
 *
 * If the data is undefined or false, we return nothing.
 * If the data is true, we return the name of the attribute.
 * If the data is a string, we return the attribute value.
 *
 * Attribute names have the regex /^[af]ttr_[\w_]+$/ e.g. 'attr_gym' or
 * 'fttr_family_friendly'
 */
export function getPropertyAvailableAttributesByGroup(
  propertyData: PropertyV2,
  attributeGroup: PropertyAttributeGroup,
  opts: GetPropertyAttributesByGroupOptions = {}
): string[] {
  const attributeNames = propertyAttributeNamesByGroup[attributeGroup];
  const availableAttributes: string[] = [];

  const availableAttributesToBeDisplayedCount = Math.min(
    opts.limit ?? attributeNames.length,
    attributeNames.length
  );

  let i = 0;
  while (
    availableAttributes.length < availableAttributesToBeDisplayedCount &&
    i < attributeNames.length
  ) {
    const attributeName = attributeNames[i];
    const attributeValue = propertyData[attributeName];
    if (attributeValue) {
      if (attributeName === 'attr_epc') {
        const epcValueUpperCased = `${attributeValue}`.toUpperCase();
        availableAttributes.push(`EPC: ${epcValueUpperCased}`);
      } else if (typeof attributeValue === 'string') {
        availableAttributes.push(attributeValue);
      } else {
        availableAttributes.push(getPropertyAttributeProperName(attributeName));
      }
    }
    i++;
  }

  return availableAttributes;
}
