import { ImageData } from './types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export interface FetchFlatImagesSuccessResponse {
  data: ImageData[];
}

interface UseFetchFlatImagesProps {
  developmentId: number;
  flatId: number;
  queryConfig?: UseQueryOptions<any>;
}

export async function fetchFlatImages(
  url: string
): Promise<FetchFlatImagesSuccessResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(
      `Could not fetch properties. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
    );
  }

  return res.json();
}

export default function useFetchFlatImages({
  developmentId,
  flatId,
  queryConfig,
}: UseFetchFlatImagesProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/property/${developmentId}/${flatId}/images`;

  return useQuery<FetchFlatImagesSuccessResponse, Error>(
    [url],
    () => fetchFlatImages(url),
    queryConfig
  );
}
