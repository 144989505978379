import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function TflRail(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="4.9 4.9 80.9 80.9"
      enableBackground="new 4.9 4.9 80.9 80.9"
      {...props}
    >
      <path
        fill="#003366"
        d="M25.8,25.7l20.5,9.4H14.5v6.4h32L30.9,49H14.5v6.3h16.4l19.5,9.4H65l-20.5-9.4h31.9V49H44.5l15.6-7.6h16.3  V35H60l-19.5-9.4H25.8 M4.9,45.3C4.9,23,23,4.9,45.3,4.9S85.8,23,85.8,45.3S67.7,85.8,45.3,85.8C23,85.8,4.9,67.7,4.9,45.3z"
      />
    </SvgIcon>
  );
}
