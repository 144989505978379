import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function TflTube(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 615.3 500"
      enableBackground="new 0 0 615.3 500"
      {...props}
    >
      <g>
        <path
          fill="#DC241F"
          d="M469.5,250c0,89.1-72.3,161.3-161.3,161.3c-89.1,0-161.3-72.2-161.3-161.3S219,88.7,308.1,88.7
		C397.2,88.7,469.5,160.9,469.5,250 M308.1,0C170,0,58.1,111.9,58.1,250c0,138.1,111.9,250,250,250c138.1,0,250-111.9,250-250
		C558.1,111.9,446.2,0,308.1,0"
        />
        <rect x="0" y="199.5" fill="#0019A8" width="615.3" height="101.1" />
        <path
          fill="#FFFFFF"
          d="M71.9,268.6c-4.2,5.2-10.5,8.5-18.3,8.5c-7.8,0-14-3.2-18.4-8.6c-3.4-4.1-4.9-9-4.9-16V224h10.2v28.6
		c0,8.9,5.1,14.7,13.1,14.7c8.2,0,13.2-5.9,13.2-14.7V224h10.1v28.2C76.9,259.4,75.6,264.1,71.9,268.6"
        />
        <polygon
          fill="#FFFFFF"
          points="122.6,276.1 94.9,240.2 94.9,276.1 84.7,276.1 84.7,224 94.9,224 122.6,260.1 122.6,224 132.8,224
		132.8,276.1 	"
        />
        <path
          fill="#FFFFFF"
          d="M155.4,276.1h-14.5V224h17.8c18,0,27.7,11.9,27.7,25.4C186.3,263.4,176.1,276.1,155.4,276.1 M156.8,232.8
		H151V267h5c12.1,0,20.1-6.8,20.1-17.2C176,239.3,168.8,232.8,156.8,232.8"
        />
        <polygon
          fill="#FFFFFF"
          points="192.7,276.1 192.7,224 225,224 225,233.1 202.8,233.1 202.8,243.9 221.1,243.9 221.1,253.1
		202.8,253.1 202.8,266.8 226.9,266.8 226.9,276.1 	"
        />
        <path
          fill="#FFFFFF"
          d="M261.6,276.1l-14.4-20.8h-4.7v20.8h-10.1V224h16.9c10.7,0,17.7,5.6,17.7,15.2c0,6.4-3.6,11.5-9.8,13.7
		l16.4,23.1H261.6z M248.4,232.8h-5.8v13.7h4.8c5.9,0,9.4-2.6,9.4-7.2C256.9,235.3,253.7,232.8,248.4,232.8"
        />
        <path
          fill="#FFFFFF"
          d="M301.4,277.1c-16.3,0-28.3-11.4-28.3-27.1c0-15.3,12-27,28.2-27c6.1,0,12.3,1.6,18.1,4.6v10.6
		c-4.9-3.5-11.2-5.9-17.4-5.9c-11.3,0-18.6,8.1-18.6,17.9c0,10,7.4,17.8,18.3,17.8c2.7,0,5.3-0.2,8-1.2v-11.2h-8.5v-8.9h18.7v24.8
		C313.6,275.3,308.1,277.1,301.4,277.1"
        />
        <path
          fill="#FFFFFF"
          d="M356.8,276.1l-14.4-20.8h-4.7v20.8h-10.1V224h16.9c10.7,0,17.7,5.6,17.7,15.2c0,6.4-3.6,11.5-9.8,13.7
		l16.4,23.1H356.8z M343.5,232.8h-5.8v13.7h4.8c5.9,0,9.4-2.6,9.4-7.2C352,235.3,348.8,232.8,343.5,232.8"
        />
        <path
          fill="#FFFFFF"
          d="M395.5,277.1c-15,0-27.3-11.3-27.3-27.1c0-15.7,12.4-27,27.3-27c14.9,0,27.3,11.3,27.3,27.1
		C422.9,265.8,410.4,277.1,395.5,277.1 M395.5,233c-9.8,0-17,7.4-17,17.1c0,9.6,7.2,17,17,17c9.8,0,17-7.4,17-17
		C412.6,240.4,405.3,233,395.5,233"
        />
        <path
          fill="#FFFFFF"
          d="M470.5,268.6c-4.2,5.2-10.5,8.5-18.3,8.5c-7.8,0-14-3.2-18.4-8.6c-3.4-4.1-4.9-9-4.9-16V224h10.1v28.6
		c0,8.9,5.2,14.7,13.1,14.7c8.2,0,13.2-5.9,13.2-14.7V224h10.1v28.2C475.5,259.4,474.2,264.1,470.5,268.6"
        />
        <polygon
          fill="#FFFFFF"
          points="521.3,276.1 493.5,240.2 493.5,276.1 483.3,276.1 483.3,224 493.5,224 521.3,260.1 521.3,224
		531.4,224 531.4,276.1 	"
        />
        <path
          fill="#FFFFFF"
          d="M554,276.1h-14.5V224h17.8c18,0,27.7,11.9,27.7,25.4C585,263.4,574.7,276.1,554,276.1 M555.5,232.8h-5.9
		V267h5c12.1,0,20.1-6.8,20.1-17.2C574.7,239.3,567.4,232.8,555.5,232.8"
        />
      </g>
    </SvgIcon>
  );
}
