import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '../../../../../atoms';

export interface TextOverlayImageProps {
  onClick?: () => void;
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textOverlayImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      width: '100%',
      height: '100%',
      padding: theme.spacing(0.25),

      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: theme.palette.grey['100'],
    },
  })
);

export default function TextOverlayImage({
  onClick,
  text = 'View all',
}: TextOverlayImageProps) {
  const classes = useStyles();

  return (
    <Box className={classes.textOverlayImage} onClick={onClick}>
      <Typography variant="subtitle1">{text}</Typography>
    </Box>
  );
}
