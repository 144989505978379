import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    pin: {
      position: 'absolute',
      cursor: 'pointer',
      textAlign: 'center',
      '& svg': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        fontSize: 24,
        padding: 4,
      },
      '& img': {
        width: 25,
      },
    },

    pinNoHover: {},

    pinHover: {},

    pinClick: {
      display: 'flex !important',
      cursor: 'default',
      flexDirection: 'column',
      background: '#ffffff',
      fontSize: 14,
      width: 'max-content',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'start',
      padding: '15px 20px 10px 5px',
      position: 'absolute',
      zIndex: 1000,
      borderRadius: 8,
      boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
      top: -5,
      left: 10,
      transform: 'translate(-50%, -100%)',
      '& p': {
        textAlign: 'left',
        marginLeft: 3,
        fontSize: 13,
        fontWeight: 300,
      },
      '&::before': {
        content: "''",
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid #ffffff',
        position: 'absolute',
        left: '49%',
        bottom: -5,
      },
      '& svg': {
        color: theme.palette.gray.dark,
        backgroundColor: theme.palette.primary.contrastText,
        marginTop: -3,
      },
    },
    infomation: {
      display: 'flex',
    },
    closeBtn: {
      position: 'absolute',
      top: 3,
      right: 0,
      cursor: 'pointer',
    },
    fontBold: {
      '& p': {
        fontWeight: 600,
      },
    },
    roomIcon: {
      backgroundColor: 'inherit !important',
      color: `${theme.palette.primary.main} !important`,
      fontSize: '30px !important',
      padding: '0 !important',
      marginLeft: '-2px',
    },
    stationIcon: {
      backgroundColor: 'transparent !important',
    },
    storeIcon: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    schoolIcon: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  })
);
