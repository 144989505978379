import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import pubFetcher from '../utils/fetcher';
import API_ENDPOINTS from '../endpoints';

export interface SendEmailToUsArgs {
  subject: string;
  text?: string;
  html?: string;
}

export interface SendEmailToUsResponse {
  data: string;
}

export default function useSendEmailToUs(
  queryConfig?: UseMutationOptions<
    SendEmailToUsResponse,
    Error,
    SendEmailToUsArgs
  >
) {
  return useMutation(
    (body) =>
      pubFetcher(API_ENDPOINTS.CONTACT_SEND_EMAIL_TO_US, {
        method: 'POST',
        body: JSON.stringify(body),
      }),
    queryConfig
  );
}
