import React from 'react';
import { Box, Collapse } from '@material-ui/core';
import { PropertyV2 } from '../../../../../api/property/types';
import useStyles from './styles/ExpandedDetails';
import PropertyAttributesSection from '../Common/PropertyAttributesSection';
import {
  ExpandedDetailsCategories,
  getExpandedDetailsCategoryTruthyAttributes,
} from './expandedDetailsCategories';
import { getPropertyAttributeLabel } from '../Common/propertyAttributes';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2;
  isExpanded: boolean;
}

// ========== COMPONENTS ========== //

export default function ExpandedDetails({ data, isExpanded }: ComponentProps) {
  const classes = useStyles();

  const propertyFacilitiesAttributes =
    getExpandedDetailsCategoryTruthyAttributes(
      ExpandedDetailsCategories.PROPERTY_FACILITIES,
      data
    ).map((attribute) => ({
      attributeName: attribute,
      attributeLabel: getPropertyAttributeLabel(attribute, data),
    }));

  const furnitureAttributes = getExpandedDetailsCategoryTruthyAttributes(
    ExpandedDetailsCategories.FURNITURE,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  const appliancesAttributes = getExpandedDetailsCategoryTruthyAttributes(
    ExpandedDetailsCategories.APPLIANCES,
    data
  ).map((attribute) => ({
    attributeName: attribute,
    attributeLabel: getPropertyAttributeLabel(attribute, data),
  }));

  return (
    <Box>
      <Collapse in={isExpanded}>
        <Box className={classes.ctn}>
          {/* Property facilities */}

          {propertyFacilitiesAttributes.length > 0 && (
            <PropertyAttributesSection
              title="Property facilities"
              attributes={propertyFacilitiesAttributes}
            />
          )}

          {/* Furniture */}

          {furnitureAttributes.length > 0 && (
            <PropertyAttributesSection
              title="Furniture"
              attributes={furnitureAttributes}
            />
          )}

          {/* Appliances */}

          {appliancesAttributes.length > 0 && (
            <PropertyAttributesSection
              title="Appliances"
              attributes={appliancesAttributes}
            />
          )}
        </Box>
      </Collapse>
    </Box>
  );
}
