import * as React from 'react';
import { AppBar, Tabs, Tab, Box, Container } from '@material-ui/core';
import useStyles from './styles';
import { AreaDetailsFromApi } from '../../../../../api/property/usePropertyAreaDetails';
import MarkdownDisplay from '../../../../atoms/MarkdownDisplay/MarkdownDisplay';
import parseAreaGuideTitle from './parseAreaGuideTitle';
import handleAreaGuideTitleClick from './handleAreaGuideTitleClick';

interface ComponentProps {
  areaGuide?: AreaDetailsFromApi[];
  developmentId: number;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AreaGuide({
  areaGuide,
  developmentId,
}: ComponentProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.explore} id="area-guide">
      <Container className={classes.container}>
        <Box className={classes.root}>
          <AppBar position="static" className={classes.tabs}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Area guide tabs"
              orientation="vertical"
              textColor="primary"
            >
              {Array.isArray(areaGuide) &&
                areaGuide.map((guide) => {
                  const parsedTitle = parseAreaGuideTitle(guide.title);

                  return (
                    <Tab
                      key={parsedTitle}
                      label={parsedTitle}
                      className={classes.tab}
                      {...a11yProps(parsedTitle)}
                      onClick={() =>
                        handleAreaGuideTitleClick(guide.title, developmentId)
                      }
                    />
                  );
                })}
            </Tabs>
          </AppBar>
          {Array.isArray(areaGuide) &&
            areaGuide.map((guide, index) => (
              <TabPanel
                key={index}
                value={value}
                index={index}
                className={classes.tabContent}
              >
                <Box>
                  <MarkdownDisplay markdownContent={guide.content} />
                </Box>
              </TabPanel>
            ))}
        </Box>
      </Container>
    </Box>
  );
}
