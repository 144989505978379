import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import useStyles from './styles/ExpansionPanel';

interface ComponentProps {
  headerText: string;
  children?: React.ReactNode;
  onChange?: (v: boolean) => void;
}

export default function ExpansionPanel({
  headerText,
  children,
  onChange,
}: ComponentProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const handleOnChange = () => {
    toggleExpanded();
    if (typeof onChange === 'function') {
      onChange(!expanded);
    }
  };

  return (
    <Box width="100%">
      <Accordion
        className={classes.customAccordion} //consider remove after fixing
        expanded={expanded}
        onChange={handleOnChange}
      >
        <AccordionSummary
          id={`${headerText}-header`}
          aria-controls={`${headerText}-content`}
          expandIcon={
            expanded ? (
              <RemoveIcon className={classes.icon} />
            ) : (
              <AddIcon className={classes.icon} />
            )
          }
          className={classes.customAccordionSummary}
        >
          <Typography variant="h6">{headerText}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.customAccordionDetails}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
