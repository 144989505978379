import * as React from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  Box,
  Hidden,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Favorite, FavoriteBorder, Home, Phone } from '@material-ui/icons';
import clsx from 'clsx';
import { AdUnit, Button } from '../../../../atoms';
import Logo from '../../../../../images/logo/v2/BTR_Logo-09.svg';
import { PropertyV2 } from '../../../../../api/property/types';
import { useAddDevelopmentToFavourites } from '../../../../../api/auth/me/favouriteDevelopments';
import useAuthToken from '../../../../../store/auth/hooks/useAuthToken';
import useMyUserDetails from '../../../../../api/auth/me/useMyUserDetails';
import useStyles from './styles/ExtraInfo';
import SvgIconImg from '../../../../atoms/SvgIconImg/SvgIconImg';
import {
  ClaimDevelopmentFormDialog,
  createEnquireMailToBody,
  EnquireFormDialog,
} from '../../../../organisms';
import Emoji from '../../../../atoms/Emoji/Emoji';
import { formatMoney } from '../../../../../utils/number';
import gtmDataLayerPSV, {
  gtmDataLayerClaimDevelopmentButtonPush,
  gtmDataLayerPhoneButtonPush,
  gtmDataLayerWebsiteButtonPush,
} from '../../../../../utils/marketing/eventPushes/gtmDataLayerPSV';
import { getPropertyAttributes } from '../../../../../utils/property';
import { getPropertyAttributeLabel } from '../Common/propertyAttributes';
import useGetLandLordById from '../../../../../api/landlords/useGetLandLordById';
import ClaimThisDevelopment from './ClaimThisDevelopment/ClaimThisDevelopment';
import getLandlordUrlFromId from './ClaimThisDevelopment/getLandlordUrlFromId';
import AdUnitContainer from '../../AdUnitContainer/AdUnitContainer';
import getEnquiryFormEmailTo from '../utils/getEnquiryFormEmailTo';
import { useSnackbar } from '../../../../../hooks';
import { useSendEmailToUs } from '../../../../../api/contact';
import isDevelopmentClaimed from '../utils/isDevelopmentClaimed';
import { Snackbar, SnackbarType } from '../../../../molecules';
import { setItemInLocalStorage } from '../../../../../utils/localStorage';
import { LOCAL_STORAGE_NAME } from '../../../../../config/localStorage';

// ========== TYPES ========== //

interface ComponentProps {
  property: PropertyV2;
  data?: PropertyV2[];
  cheapestRent: number;
  setShowContactFab?: (show: boolean) => void;
}

// ========== COMPONENT ========== //

function formatContactPhone(contactPhone: string): string {
  try {
    const formattedContactPhone = parsePhoneNumber(
      contactPhone,
      'GB'
    )?.formatNational();
    return formattedContactPhone;
  } catch (err) {
    return '';
  }
}

const adUnitSlotIds: string[] = [
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_SINGLE_VIEW_SIDEBAR_01_SLOT_ID ??
    '',

  // TODO: re-enable when we confirm UX is fine
  // process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_SINGLE_VIEW_SIDEBAR_02_SLOT_ID ?? '',
];

const ExtraInfo = ({
  property,
  cheapestRent,
  data,
  setShowContactFab,
}: ComponentProps) => {
  const classes = useStyles();
  const contactButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  const authToken = useAuthToken();

  const { data: managerData } = useGetLandLordById(property.manager_id ?? 0, {
    enabled: !!property.manager_id,
  });

  const { data: myUserDetailsData, status: myUserDetailsStatus } =
    useMyUserDetails({ authToken });

  const { mutate: sendEmailToUs, status: sendEmailToUsStatus } =
    useSendEmailToUs();

  const { displaySnackbar, hideSnackbar, snackbarState } = useSnackbar();

  React.useEffect(() => {
    if (sendEmailToUsStatus === 'success') {
      displaySnackbar(
        SnackbarType.SUCCESS,
        'Thanks! We have received your query.'
      );
    } else if (sendEmailToUsStatus === 'error') {
      displaySnackbar(
        SnackbarType.ERROR,
        'Sorry, something went wrong. If this persists please email us at info@buildtorent.io'
      );
    }
  }, [sendEmailToUsStatus]);

  const developmentClaimed = isDevelopmentClaimed(property.organization_id);

  const enquireFormDialogEmailTo = getEnquiryFormEmailTo({
    organizationId: property.organization_id,
    contactEmail: property.contact_email,
  });

  const {
    mutate: addDevelopmentToFavourites,
    status: addDevelopmentToFavouritesStatus,
  } = useAddDevelopmentToFavourites();

  const {
    mutate: removeDevelopmentFromFavourites,
    status: removeDevelopmentFromFavouritesStatus,
  } = useAddDevelopmentToFavourites();

  const [claimDevelopmentFormDialogOpen, setClaimDevelopmentFormDialogOpen] =
    React.useState(false);

  const [enquireFormDialogOpen, setEnquireFormDialogOpen] =
    React.useState(false);

  const isFavourite =
    myUserDetailsData?.user.favourite_development_ids.includes(
      property.development_id
    );

  // TODO: re-enable when we have favourite API
  // const isFavouriteBtnDisabled =
  //   !myUserDetailsData ||
  //   myUserDetailsStatus === 'loading' ||
  //   addDevelopmentToFavouritesStatus === 'loading' ||
  //   removeDevelopmentFromFavouritesStatus === 'loading';
  const isFavouriteBtnDisabled = true;

  const handleFavouriteBtnClick = () => {
    if (myUserDetailsData) {
      try {
        if (isFavourite) {
          void removeDevelopmentFromFavourites({
            developmentId: property.development_id,
            authToken,
          });
        } else {
          void addDevelopmentToFavourites({
            developmentId: property.development_id,
            authToken,
          });
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error('Please log in first!');
    }
  };

  const propertyAttributes = getPropertyAttributes(property, {
    whatToInclude: 'all',
    limit: 4,
  });

  const bedrooms = data
    ?.sort((a, b) => a.bedrooms_count - b.bedrooms_count)
    .map((property) => ({
      title: property.flat_title.replace('flat', ''),
      value: property.rent_pcm,
      bedCount: property.bedrooms_count,
    }));

  const onWindowScroll = () => {
    if (contactButtonRef.current != null) {
      if (
        window.pageYOffset >
        contactButtonRef.current.offsetTop +
          contactButtonRef.current.offsetHeight
      ) {
        setShowContactFab?.(true);
      } else {
        setShowContactFab?.(false);
      }
    }
  };

  const handleEnquireButtonClick = () => {
    setEnquireFormDialogOpen(true);
    gtmDataLayerPSV({
      developmentId: property.development_id,
      developmentTitle: property.title,
      flatId: undefined,
      flatTitle: undefined,
      flatCategory: undefined,
      flatRentPcm: undefined,
    });
  };

  const handlePhoneButtonClick = () => {
    gtmDataLayerPhoneButtonPush({
      developmentId: property.development_id,
    });
  };

  const handleWebsiteButtonClick = () => {
    gtmDataLayerWebsiteButtonPush({
      developmentId: property.development_id,
    });
  };

  const handleClaimThisDevelopmentClick = () => {
    setClaimDevelopmentFormDialogOpen(true);
    gtmDataLayerClaimDevelopmentButtonPush({
      developmentId: property.development_id,
    });
  };

  const claimThisDevelopmentElement = (
    <ClaimThisDevelopment
      managerData={
        managerData
          ? {
              url: getLandlordUrlFromId(managerData.data.landlord_id),
              logoSrc: managerData.data.landlord_logo_src ?? '',
              name: managerData.data.landlord_name,
            }
          : undefined
      }
      handleClaimThisDevelopmentButtonClick={handleClaimThisDevelopmentClick}
    />
  );

  const adUnitElement = adUnitSlotIds.map((slotId) => (
    <Box key={slotId} mt={1}>
      <AdUnitContainer>
        <AdUnit adSlot={slotId} />
      </AdUnitContainer>
    </Box>
  ));

  // TODO: temporary solution to avoid landlords being spammed with email
  // const contactPhone = (data && data[0]?.contact_phone) ?? '';
  const contactPhone = '';

  return (
    <Box id="quick-start" className={classes.ctn}>
      {/* PROMOTION SECTION #1 */}

      <Box
        className={clsx(
          classes.sectionCtn,
          classes.promotionSectionCtn,
          classes.dNone
        )}
      >
        <Box className={classes.promotionSectionTitleCtn}>
          <SvgIconImg src={Logo} alt="logo" width={24} height={24} />
          <Typography variant="h6">
            Promotion <Emoji emoji="🎉" />
          </Typography>
        </Box>
        <Typography>[Promotion placeholder]</Typography>
      </Box>

      {/* QUICK STATS SECTION */}

      <Box className={clsx(classes.sectionCtn, classes.quickStatsSectionCtn)}>
        <Typography
          className={clsx(classes.title, classes.showMobile)}
          variant="h4"
        >
          {property.title}
        </Typography>
        {/* Favourite button */}

        <Box className={classes.rentAndFavouriteBtnSectionCtn}>
          <Typography>
            From
            <span className={classes.cheapestRentAmt}>
              {` ${formatMoney(cheapestRent, 'GBP')} `}
            </span>
            /month
          </Typography>
          <Tooltip
            title={
              myUserDetailsData
                ? isFavourite
                  ? 'Remove development from favourites'
                  : 'Add development to favourites'
                : 'Log in to add this development as favourites'
            }
            disableFocusListener={isFavouriteBtnDisabled}
            disableHoverListener={isFavouriteBtnDisabled}
            disableTouchListener={isFavouriteBtnDisabled}
          >
            <span>
              <IconButton
                disabled={isFavouriteBtnDisabled}
                className={classes.favoriteBtn}
                onClick={handleFavouriteBtnClick}
              >
                {isFavourite ? (
                  <Favorite
                    color={isFavouriteBtnDisabled ? 'disabled' : 'primary'}
                  />
                ) : (
                  <FavoriteBorder
                    color={isFavouriteBtnDisabled ? 'disabled' : 'primary'}
                  />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        {/* Property's top tags */}

        <List className={classes.propertyTopTags}>
          {propertyAttributes.map((attribute) => (
            <ListItem key={attribute}>
              {getPropertyAttributeLabel(attribute, property)}
            </ListItem>
          ))}
        </List>

        {/* Phone button */}

        {contactPhone && (
          <Button
            variant="outlined"
            color="primary"
            id="phone-button-extra-info"
            className={classes.phoneButton}
            href={`tel:${contactPhone}`}
            ref={contactButtonRef}
            onClick={handlePhoneButtonClick}
          >
            <Phone />
            {formatContactPhone(contactPhone)}
          </Button>
        )}

        {/* Enquire button */}

        <Button
          id="enquire-button-extra-info"
          variant="contained"
          color="primary"
          className={classes.enquireButton}
          onClick={handleEnquireButtonClick}
        >
          Enquire
        </Button>

        {property.development_website && (
          <Button
            variant="outlined"
            color="primary"
            id="website-button-extra-info"
            className={classes.websiteButton}
            href={property.development_website}
            onClick={handleWebsiteButtonClick}
          >
            Website
          </Button>
        )}

        {/* Claim this development - Mobile */}
        <Hidden mdUp>
          <>
            {claimThisDevelopmentElement}
            {adUnitElement}
          </>
        </Hidden>

        {/* Bedrooms */}

        <Box className={classes.bedroomsCtn} position="relative">
          {bedrooms
            ?.slice(0, Math.round((bedrooms?.length || 0) / 2))
            .map((item, i) => {
              const length = Math.round((bedrooms?.length || 0) / 2);
              const brs = [bedrooms?.[i], bedrooms?.[i + length]].filter(
                (val) => !!val
              );
              return (
                <Box
                  key={item.bedCount}
                  display="flex"
                  justifyContent="space-between"
                  className={classes.bedroomsRow}
                >
                  {brs.map((bedroom) => (
                    <Box
                      key={bedroom.bedCount}
                      justifyContent="space-between"
                      display="flex"
                      flex={brs.length === 2 ? 1 : 0.5}
                    >
                      <Typography className={classes.bedroomTitle}>
                        {bedroom.title}
                      </Typography>
                      <Typography>
                        {formatMoney(bedroom.value, 'GBP', 0)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            })}
        </Box>

        {/* Popularity box */}

        <Box className={clsx(classes.popularityBox, classes.dNone)}>
          <Box>
            <Typography variant="h6">Popular!</Typography>
            <Typography>XXX people viewed this property</Typography>
          </Box>
          <Box className={classes.logoHome}>
            <Home fontSize="large" />
          </Box>
        </Box>
      </Box>

      {/* PROMOTION SECTION #2 */}

      <Box
        className={clsx(
          classes.sectionCtn,
          classes.promotionSection2Ctn,
          classes.dNone
        )}
      >
        <Box className={classes.promotionSectionTitleCtn}>
          <SvgIconImg src={Logo} alt="logo" width={24} height={24} />
          <Typography variant="h6">
            Promotion <Emoji emoji="🎉" />
          </Typography>
        </Box>
        <Typography>[Promotion placeholder]</Typography>
      </Box>

      {/* Claim this development - desktop */}
      <Hidden smDown>
        <>
          {claimThisDevelopmentElement}
          {adUnitElement}
        </>
      </Hidden>

      <ClaimDevelopmentFormDialog
        open={claimDevelopmentFormDialogOpen}
        handleClose={() => {
          setClaimDevelopmentFormDialogOpen(false);
        }}
        handleSubmit={() => {
          setClaimDevelopmentFormDialogOpen(false);
        }}
        openEmailClientOnSubmit
        developmentId={property.development_id}
        developmentTitle={property.title}
        developmentAddress={property.address ?? ''}
        developmentArea={property.area ?? ''}
      />

      {enquireFormDialogOpen && (
        <EnquireFormDialog
          open={enquireFormDialogOpen}
          handleClose={() => {
            setEnquireFormDialogOpen(false);
          }}
          handleSubmit={({
            name,
            email,
            bedroom,
            moveInDate,
            phone,
            numberOfTenants,
          }) => {
            if (!developmentClaimed) {
              const { subject, bodyText } = createEnquireMailToBody({
                developmentTitle: property.title,
                developmentAddress: property.address ?? '',
                developmentArea: property.area ?? '',
                name,
                email,
                bedroom,
                moveInDate,
                phone,
                numberOfTenants,
              });
              sendEmailToUs({
                subject,
                text: bodyText,
              });
            }

            setItemInLocalStorage({
              key: LOCAL_STORAGE_NAME.ENQUIRE_FORM_TENANT_DATA,
              value: JSON.stringify({
                name,
                email,
                bedroom,
                moveInDate,
                phone,
                numberOfTenants,
              }),
            });

            setEnquireFormDialogOpen(false);
          }}
          openEmailClientOnSubmit={developmentClaimed}
          developmentTitle={property.title}
          developmentAddress={property.address ?? ''}
          developmentArea={property.area ?? ''}
          emailTo={enquireFormDialogEmailTo}
          developmentId={property.development_id}
        />
      )}

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        {snackbarState.content}
      </Snackbar>
    </Box>
  );
};

export default ExtraInfo;
