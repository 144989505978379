import { OrgFile } from '../../../../../api/orgs/types';
import { swapArrayItem } from '../../../../../utils/array';

/**
 * Given an image array and a thumbnail ID, put the thumbnail image on top. Will
 * return a copy of the original image array.
 *
 * Note: if the thumbnail ID is not found in the image array, this won't have
 * any effect.
 */
export default function putThumbnailImageOnTop<T extends OrgFile>(
  orgImages: T[],
  thumbnailId: string
): T[] {
  if (orgImages.length > 0 && thumbnailId) {
    let newOrgImages: T[] = [];
    let i = 0;

    while (i < orgImages.length) {
      const { orgimages_id } = orgImages[i];

      if (orgimages_id === thumbnailId) {
        newOrgImages = swapArrayItem(orgImages, 0, i);
        break;
      }

      i++;
    }

    return newOrgImages;
  }

  return orgImages;
}
