import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function TflDlr(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 615.3 500"
      enableBackground="new 0 0 615.3 500"
      {...props}
    >
      <g>
        <path
          fill="#00AFAD"
          d="M469.5,250c0,89.1-72.3,161.3-161.3,161.3c-89.1,0-161.3-72.2-161.3-161.3c0-89.1,72.2-161.3,161.3-161.3   C397.2,88.7,469.5,160.9,469.5,250 M308.1,0C170,0,58.1,111.9,58.1,250c0,138.1,111.9,250,250,250c138.1,0,250-111.9,250-250   C558.1,111.9,446.2,0,308.1,0"
        />
        <rect y="199.5" fill="#0019A8" width="615.3" height="101.1" />
        <path
          fill="#FFFFFF"
          d="M247.7,276.3h-14.5v-52.1H251c18,0,27.7,11.9,27.7,25.4C278.7,263.5,268.5,276.3,247.7,276.3 M249.2,233   h-5.9v34.1h5c12.1,0,20.1-6.8,20.1-17.2C268.4,239.5,261.1,233,249.2,233"
        />
        <polygon
          fill="#FFFFFF"
          points="293.8,276.3 293.8,224.2 304,224.2 304,266.7 325.7,266.7 325.7,276.3  "
        />
        <path
          fill="#FFFFFF"
          d="M368.8,276.3l-14.4-20.8h-4.7v20.8h-10.2v-52.1h16.9c10.7,0,17.7,5.6,17.7,15.2c0,6.4-3.6,11.5-9.8,13.7   l16.4,23.1H368.8z M355.5,233h-5.8v13.7h4.8c5.9,0,9.4-2.6,9.4-7.2C364,235.4,360.8,233,355.5,233"
        />
      </g>
    </SvgIcon>
  );
}
