import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    grey: {
      padding: '10px 15px 40px',
      background: '#f3f3f3',
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      padding: 0,
      margin: 'auto',
    },
    content: {
      fontSize: '1rem',
      '& h1, h2, h3, h4, h5, h6': {
        fontSize: '1rem',
      },
    },
    titleFaq: {
      fontSize: '2rem',
      color: theme.palette.gray.dark,
    },
    faqSectionHeader: {
      borderBottom: `1px solid ${theme.palette.gray.main}`,
      paddingBottom: 20,
      '& h5': {
        fontSize: '1.125rem',
        color: theme.palette.gray.dark,
      },
    },
  })
);
