import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      position: 'absolute',
      top: 0,
      right: 70,
      color: '#000',
      [theme.breakpoints.down('sm')]: {
        right: 0,
        top: 5,
      },
    },
    dialogContent: {
      padding: 0,
    },
    tabCategory: {
      listStyle: 'none',
      padding: '0 15px',
      display: 'flex',
      justifyContent: 'center',
    },
    tabCategoryItem: {
      paddingLeft: 10,
      paddingRight: 10,
      borderBottom: '3px solid #4c4c4c',
      cursor: 'pointer',
      color: '#9e9e9e',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    active: {
      color: '#000',
      fontWeight: 'bold',
      borderBottom: '3px solid #fcce01',
    },
    bgImageGallery: {
      backgroundColor: '#fff',
      height: '100vh',
    },
    originalClass: {
      minHeight: 'calc(100vh - 130px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    thumbnailClass: {
      '& .image-gallery-thumbnail-image': {
        height: 60,
        objectFit: 'contain',
      },
    },
  })
);
