import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  SvgIconProps,
  Theme,
} from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps {
  icon: React.FunctionComponent<SvgIconProps>;
  children?: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      alignItems: 'center',

      '& a': {
        color: theme.palette.teal.main,
      },
      '& a:hover': {
        color: theme.palette.teal.dark,
      },
    },

    icon: {
      marginRight: theme.spacing(2),
    },
  })
);

// ========== COMPONENT ========== //

export default function PropertySummaryTextRow({
  icon: Icon,
  children,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Icon className={classes.icon} color="secondary" />
      {children}
    </Box>
  );
}
