import * as React from 'react';
import { Box } from '@material-ui/core';
import { LatLngLiteral } from '../../../../../api/googleMaps/gcpMaps/types';
import { NearbyPlace } from '../../../../../models/nearbyPlaces';
import Map from '../../../../organisms/Map/Map';
import { PropertySingleViewSection } from '../config';
import useStyles from './MapSection.styles';
import { Button } from '../../../../atoms';

export interface MapSectionProps {
  geometry: LatLngLiteral;
  nearbyPlaces: NearbyPlace[];
  address?: string;
}

export default function MapSection({
  geometry,
  nearbyPlaces,
  address,
}: MapSectionProps) {
  const classes = useStyles();

  const [mapLoaded, setMapLoaded] = React.useState(false);

  const mapRef = React.useRef();
  const mapsRef = React.useRef();

  const mapConfig = {
    center: geometry,
    zoom: 16,
    hoverDistance: 24,
  };

  return (
    <Box
      className={classes.ctn}
      pt={2}
      id={PropertySingleViewSection.MAP_SECTION}
    >
      {mapLoaded && (
        <Map
          mapRef={mapRef}
          mapsRef={mapsRef}
          mapConfig={mapConfig}
          nearbyPlaces={nearbyPlaces}
          pinsData={[
            {
              lat: geometry.lat,
              lng: geometry.lng,
              data: { name: address, type: 'unknown', img: 'room' },
            },
          ]}
        />
      )}

      {!mapLoaded && (
        <Box className={classes.loadMapBtnCtn}>
          <img
            className={classes.loadMapBtnCtnBkg}
            alt="maps background"
            src="https://res.cloudinary.com/rmnfrk9/image/upload/v1652135273/static/google-maps-preview_nucx1q.webp"
          />

          <Button
            className={classes.loadMapBtn}
            variant="contained"
            color="primary"
            onClick={() => setMapLoaded(true)}
          >
            Load Map
          </Button>
        </Box>
      )}
    </Box>
  );
}
