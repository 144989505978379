import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: '#62C4B1',
    },

    ctn: {
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
    },
    cheapestRentAmt: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    mobileShow: {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    mobileNone: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mobileNoneLabel: {
      '& span': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
    propertyTopTags: {
      display: 'none',
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
      '& > li': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: 'auto',
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        padding: theme.spacing(0.5, 2),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },

    description: {
      marginBottom: theme.spacing(2),
      '& a': {
        color: '#62C4B1 !important',
      },
    },

    detailsCtn: {
      display: 'flex',
      flexDirection: 'column',

      width: '100%',

      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },

    detailsRow: {
      display: 'flex',
      alignItems: 'center',

      '& a': {
        color: theme.palette.teal.main,
      },
      '& a:hover': {
        color: theme.palette.teal.dark,
      },

      // This targets the icon
      '& > *:first-child': {
        marginRight: theme.spacing(2),
      },
    },

    detailsRow1: {
      display: 'flex',
      '& > *': {
        marginRight: theme.spacing(1.5),
      },
      '& img': {
        marginTop: 5,
        width: 'auto',
        height: 20,
        color: '#000000',
      },
      [theme.breakpoints.down('xs')]: {
        '& p ': {
          fontSize: '0.875rem',
        },
      },
    },
    textLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },

    wip: {
      color: theme.palette.grey['500'],
    },
    titlePlace: {
      textTransform: 'capitalize',
      marginBottom: 5,
      marginTop: 2,
    },
    nearby: {
      display: 'flex',
      paddingBottom: 5,
      '& span': {
        display: 'inline-flex',
        marginLeft: 5,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'initial',
        paddingBottom: 10,
        '& svg': {
          fontSize: '0.875rem',
        },
        '& span': {
          alignItems: 'center',
          marginLeft: 7,
        },
      },
    },
  })
);
