import { Box, Container, Typography } from '@material-ui/core';
import * as React from 'react';
import useStyles from './styles';
import { PropertySingleViewSection } from '../config';

export interface VirtualTourProps {
  src: string;
}

export default function VirtualTour({ src }: VirtualTourProps) {
  const classes = useStyles();

  return (
    <Box
      className={classes.virtualTour}
      id={PropertySingleViewSection.VIRTUAL_TOUR_SECTION}
    >
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title} id="virtual-tour">
          Virtual Tour
        </Typography>
        <Box>
          <iframe
            allowFullScreen
            src={src}
            frameBorder="0"
            style={{
              width: '100%',
              height: 500,
            }}
          />
        </Box>
      </Container>
    </Box>
  );
}
