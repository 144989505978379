import * as React from 'react';
import {
  Box,
  Button,
  colors,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import useStyles from './styles/FlatSummaryDetails';
import { formatMoney } from '../../../../../utils/number';
import { pluralizeUsingS } from '../../../../../utils/string';
import LongSpace from '../../../../atoms/LongSpace/LongSpace';
import PropertyAttribute from '../Common/PropertyAttribute';
import PropertyAttributeIcon from '../Common/PropertyAttributeIcon';
import { PropertyV2 } from '../../../../../api/property/types';
import { FlatDetailsCategory } from '../../../../../api/property/models';
import { defaultFlatTitles } from '../../../../../utils/property/getFlatPrices';

// ========== TYPES ========== //

interface ComponentProps {
  flatTitle: string;
  flatCategory?: FlatDetailsCategory;

  bedroomsCount: number;
  furnishingOptions: string;
  bathroomType: string;

  rent: number;
  rentCurrency: string;
  rentUnit: string;

  isDetailsExpanded: boolean;

  handleToggleExpandedDetails: () => void;
  handleSelectProperty: () => void;

  // TODO: probably pass less information
  property: PropertyV2;
}

// ========== COMPONENT ========== //

const attributeIconColor = colors.grey['900'];

export default function FlatSummaryDetails({
  flatTitle,
  flatCategory,

  bedroomsCount,
  furnishingOptions,
  bathroomType,

  rent,
  rentCurrency,
  rentUnit,

  isDetailsExpanded,
  handleToggleExpandedDetails,

  handleSelectProperty,

  property,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      {/* Title & Details expand / collapse button */}

      <Box className={classes.titleAndExpandBtnCtn}>
        <Typography className={classes.title} variant="h5">
          {flatTitle}
        </Typography>

        <Tooltip
          title={
            isDetailsExpanded
              ? 'Hide property details'
              : 'Show property details'
          }
        >
          <IconButton
            aria-label={
              isDetailsExpanded
                ? `expand property details`
                : `collapse property details`
            }
            color="primary"
            onClick={handleToggleExpandedDetails}
          >
            {isDetailsExpanded ? <Remove /> : <Add />}
          </IconButton>
        </Tooltip>
      </Box>

      {/* Summary attributes */}

      <Box className={classes.attributesCtn}>
        <PropertyAttribute
          icon={
            <PropertyAttributeIcon
              attribute="fttr_bed_and_mattress"
              stroke={attributeIconColor}
              fontSize="large"
            />
          }
          text={
            bedroomsCount === 0
              ? 'Studio'
              : bedroomsCount === -1 && !!flatCategory
              ? defaultFlatTitles[flatCategory]
              : `${bedroomsCount} ${pluralizeUsingS('bedroom', bedroomsCount)}`
          }
        />
        {furnishingOptions && (
          <PropertyAttribute
            icon={
              <PropertyAttributeIcon
                attribute="attr_furnished"
                stroke={attributeIconColor}
                fontSize="large"
              />
            }
            text={furnishingOptions}
          />
        )}
        {bathroomType && (
          <PropertyAttribute
            icon={
              <PropertyAttributeIcon
                attribute="attr_bathroom_type"
                stroke={attributeIconColor}
                fontSize="large"
              />
            }
            text={bathroomType}
          />
        )}
      </Box>

      {/* Rent amount & select (enquiry) button */}

      <Box className={classes.rentAndSelectBtnCtn}>
        <Typography className={classes.rent} align="right">
          From
          <LongSpace />
          <span className={classes.rentAmt}>
            {formatMoney(rent, rentCurrency)}
          </span>
          <LongSpace />
          {rentUnit}
        </Typography>

        <Button
          id="enquire-button-flat-summary"
          variant="contained"
          color="primary"
          onClick={handleSelectProperty}
        >
          Enquire
        </Button>
      </Box>
    </Box>
  );
}
