import * as React from 'react';
import { Box, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import PropertySummary from './PropertySummary';
import ImageCarousel from './ImageCarousel';
import ExtraInfo from './ExtraInfo';
import Section from '../Common/Section';
import {
  DevelopmentImage,
  FlatImage,
  ImageData,
  VideoData,
  PropertyV2,
} from '../../../../../api/property/types';
import useStyles from './styles/MainSection';
import { NearbyPlace } from '../../../../../models/nearbyPlaces';
import { PropertySingleViewSection } from '../config';

// ========== TYPES ========== //

interface ComponentProps {
  property: PropertyV2;
  data: PropertyV2[];
  developmentImages: DevelopmentImage[] | undefined;
  developmentVideos: VideoData[] | undefined;
  flatImages: FlatImage[] | undefined;
  flatVideos: VideoData[] | undefined;
  cheapestRent: number;
  bills: [];
  nearbyPlaces: NearbyPlace[];
  developmentDetails: string;
  setShowContactFab?: (show: boolean) => void;
}

// ========== COMPONENT ========== //

export default function MainSection({
  property,
  data,
  cheapestRent,
  developmentImages,
  developmentVideos,
  flatImages,
  flatVideos,
  nearbyPlaces,
  bills,
  developmentDetails,
  setShowContactFab,
}: ComponentProps) {
  const classes = useStyles();
  const theme = useTheme();
  const showDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const propertyImages: ImageData[] =
    developmentImages && flatImages
      ? [...developmentImages, ...flatImages]
      : [];

  const propertyVideos: VideoData[] =
    developmentVideos && flatVideos
      ? [...developmentVideos, ...flatVideos]
      : [];

  return (
    <Section
      title={showDesktop ? property.title : ''}
      id={PropertySingleViewSection.MAIN_SECTION}
    >
      {/* IMAGE CAROUSEL, PROPERTY DETAILS, EXTRAS */}

      <Box className={classes.mainCtn}>
        {/* LEFT SIDE */}

        <Box>
          <ImageCarousel
            property={property}
            propertyImages={propertyImages}
            propertyVideos={propertyVideos}
          />
          <Hidden mdUp>
            <ExtraInfo
              property={property}
              cheapestRent={cheapestRent}
              data={data}
              setShowContactFab={setShowContactFab}
            />
          </Hidden>
          <PropertySummary
            data={property}
            cheapestRent={cheapestRent}
            nearbyPlaces={nearbyPlaces}
            bills={bills}
            developmentDetails={developmentDetails}
          />
        </Box>

        {/* RIGHT SIDE */}
        <Hidden smDown>
          <ExtraInfo
            property={property}
            cheapestRent={cheapestRent}
            data={data}
            setShowContactFab={setShowContactFab}
          />
        </Hidden>
      </Box>
    </Section>
  );
}
