import * as React from 'react';
import {
  Box,
  colors,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { toDashCase } from '../../../../../utils/string';

// ========== TYPES ========== //

interface ComponentProps {
  id?: string;
  title?: string;
  isGrey?: boolean;
  children?: React.ReactNode;
  className?: string;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingBottom: theme.spacing(2),

      // Prevent the horizontal scrollbar when the screen is a little bit small
      overflowX: 'hidden',
    },

    greyBkg: {
      backgroundColor: colors.grey['100'],
    },

    title: {
      marginTop: -theme.spacing(8),
      paddingTop: theme.spacing(10),
      margin: theme.spacing(2, 0),
      fontWeight: 'bold',
    },
  })
);

// ========== COMPONENT ========== //

export default function Section({
  id,
  title,
  isGrey,
  children,
  className,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box
      id={id}
      className={clsx(className, classes.ctn, isGrey && classes.greyBkg)}
    >
      <Container maxWidth="lg">
        {title && (
          <Typography
            id={toDashCase(title).toLowerCase()}
            variant="h4"
            className={classes.title}
          >
            {title}
          </Typography>
        )}
        {children}
      </Container>
    </Box>
  );
}
