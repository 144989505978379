import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
  Fireplace,
  HomeWork,
  LocalConvenienceStore,
  MonetizationOn,
  Room,
} from '@material-ui/icons';
import { Link } from '../../../../atoms';
import { PropertyV2 } from '../../../../../api/property/types';
import {
  DevelopmentAttribute,
  FlatAttribute,
  SvgIconV2Name,
} from '../../../../../api/property/models';
import useStyles from './styles/PropertySummary';
import { getAddressFromPropertyData } from '../../../../../utils/address';
import LongSpace from '../../../../atoms/LongSpace/LongSpace';
import NearestPlacesSection from './NearestPlacesSection';
import PropertySummarySubSection from './PropertySummarySubSection';
import PropertyDetails from './PropertyDetails';
import PropertySummaryTextRow from './PropertySummaryTextRow';
import {
  countPropertyAvailableAttributesByGroup,
  getPropertyAvailableAttributesByGroup,
} from '../../../../../api/property/propertyAttributes';
import { PropertyAttributeGroup } from '../utils';
import PropertyAttributesRow from '../../../../../api/property/PropertyAttributesRow';
import { NearbyPlace } from '../../../../../models/nearbyPlaces';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2;
  nearbyPlaces: NearbyPlace[];
  bills: [];
  developmentDetails: string;
  cheapestRent: number;
}

// ========== COMPONENT ========== //

export function validateAttributeValue(attributeValue) {
  return (
    (typeof attributeValue === 'string' && attributeValue !== 'false') ||
    attributeValue === true
  );
}

export function getAttributeIcon(
  attribute: FlatAttribute | DevelopmentAttribute
): SvgIconV2Name {
  return attribute
    .replace('fttr_', '')
    .replace('attr_', '')
    .replace(/_/g, '') as SvgIconV2Name;
}

export function getAttributeText(
  attributeValue: string | boolean | null | undefined,
  attributeName: string,
  attribute: FlatAttribute | DevelopmentAttribute
): string {
  if (!attribute.includes('fttr_') && !attribute.includes('attr_'))
    return `${attributeName}: ${attributeValue}`;

  return typeof attributeValue === 'string' && attributeValue !== 'true'
    ? attributeValue
    : attributeName;
}

// How many property attributes to display?
let MAX_ATTRIBUTE_DISPLAY_COUNT = 7;
if (typeof window !== 'undefined') {
  if (window.innerWidth < 600) {
    MAX_ATTRIBUTE_DISPLAY_COUNT = 10;
  }
}

export default function PropertySummary({
  data,
  nearbyPlaces,
  bills,
  developmentDetails,
}: ComponentProps) {
  const classes = useStyles();
  const [developmentDetailsExpanded, setDevelopmentDetailsExpanded] =
    React.useState<boolean>(false);
  const developmentFacilitiesToDisplay = getPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_FACILITIES,
    { limit: MAX_ATTRIBUTE_DISPLAY_COUNT }
  );
  const developmentFacilitiesCount = countPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_FACILITIES
  );
  const developmentAmenitiesToDisplay = getPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_AMENITIES,
    { limit: MAX_ATTRIBUTE_DISPLAY_COUNT }
  );
  const developmentAmenitiesCount = countPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_AMENITIES
  );
  const developmentAtmosphereToDisplay = getPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_ATMOSPHERE,
    { limit: MAX_ATTRIBUTE_DISPLAY_COUNT }
  );
  const developmentAtmosphereCount = countPropertyAvailableAttributesByGroup(
    data,
    PropertyAttributeGroup.DEVELOPMENT_ATMOSPHERE
  );

  const developmentBillsToDisplay =
    bills.length > MAX_ATTRIBUTE_DISPLAY_COUNT
      ? bills.slice(0, MAX_ATTRIBUTE_DISPLAY_COUNT)
      : bills;
  const developmentBillsCount = bills.length;
  return (
    <Box className={classes.ctn}>
      {/* ADDRESS */}

      <PropertySummarySubSection>
        <PropertySummaryTextRow icon={Room}>
          <Typography>
            {getAddressFromPropertyData(data)}
            <LongSpace />
            <Link to={`/properties/${data.development_id}#map-section`}>
              View Map
            </Link>
          </Typography>
        </PropertySummaryTextRow>
      </PropertySummarySubSection>

      {/* NEAREST PLACES */}

      <PropertySummarySubSection title="Nearest places">
        <NearestPlacesSection nearbyPlaces={nearbyPlaces} />
      </PropertySummarySubSection>

      {/* FACILITIES */}

      <PropertySummarySubSection title="Key features">
        <PropertyAttributesRow
          icon={HomeWork}
          label="Facilities"
          sectionAnchorUrl={`/properties/${data.development_id}#facilities-and-services`}
          attributes={developmentFacilitiesToDisplay}
          attributesCount={developmentFacilitiesCount}
        />
      </PropertySummarySubSection>

      {/* AMENITIES */}

      <Box className={classes.mobileNone}>
        {developmentAmenitiesToDisplay.length > 0 && (
          <PropertySummarySubSection>
            <PropertyAttributesRow
              icon={LocalConvenienceStore}
              label="Amenities"
              sectionAnchorUrl={`/properties/${data.development_id}#development-amenities`}
              attributes={developmentAmenitiesToDisplay}
              attributesCount={developmentAmenitiesCount}
            />
          </PropertySummarySubSection>
        )}
      </Box>

      {/* ATMOSPHERE */}

      <Box className={classes.mobileNoneLabel}>
        <PropertySummarySubSection>
          <PropertyAttributesRow
            icon={Fireplace}
            label="Atmosphere"
            sectionAnchorUrl={`/properties/${data.development_id}#living-atmosphere`}
            attributes={developmentAtmosphereToDisplay}
            attributesCount={developmentAtmosphereCount}
          />
        </PropertySummarySubSection>
      </Box>

      {/* BILLS INCLUDED */}

      {developmentBillsToDisplay.length > 0 && (
        <PropertySummarySubSection>
          <PropertyAttributesRow
            icon={MonetizationOn}
            label="Bills included"
            sectionAnchorUrl={`/properties/${data.development_id}#your-bills`}
            attributes={developmentBillsToDisplay}
            attributesCount={developmentBillsCount}
          />
        </PropertySummarySubSection>
      )}

      {/* Property details */}

      <PropertySummarySubSection
        id="property-details"
        title="Property details"
        sticky={true}
        developmentDetailsExpanded={developmentDetailsExpanded}
        setDevelopmentDetailsExpanded={setDevelopmentDetailsExpanded}
      >
        <PropertyDetails
          developmentDetails={developmentDetails}
          developmentDetailsExpanded={developmentDetailsExpanded}
          setDevelopmentDetailsExpanded={setDevelopmentDetailsExpanded}
        />
      </PropertySummarySubSection>
    </Box>
  );
}
