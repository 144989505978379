import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export interface AreaDetailsFromApi {
  title: string;
  content: string;
}

interface FetcherResponse {
  data: AreaDetailsFromApi[];
}

export async function fetchPropertyAreaDetails(
  url: string
): Promise<FetcherResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(
      `Could not fetch development area guide. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
    );
  }

  return res.json() as Promise<FetcherResponse>;
}

interface UsePropertyAreaDetailsProps {
  developmentId: string;
  queryConfig?: UseQueryOptions<any>;
}

interface UsePropertyAreaDetailsResponse {
  data: AreaDetailsFromApi[];
}

export default function usePropertyAreaDetails({
  developmentId,
  queryConfig,
}: UsePropertyAreaDetailsProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/property/${developmentId}/areadetails`;

  return useQuery<UsePropertyAreaDetailsResponse, Error>(
    [url],
    () => fetchPropertyAreaDetails(url),
    queryConfig
  );
}
