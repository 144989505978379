import { FlatInDb } from './types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export interface FetchPropertyByDevelopmentAndFlatIdSuccessResponse {
  data: FlatInDb;
  meta: {
    developmentId: number;
    flatId: number;
  };
}

interface UseFetchPropertyByDevelopmentAndFlatIdProps {
  developmentId: number;
  flatId: number;

  queryConfig?: UseQueryOptions<any>;
}

export async function fetchPropertyByDevelopmentAndFlatId(
  url: string
): Promise<FetchPropertyByDevelopmentAndFlatIdSuccessResponse> {
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(
      `Could not fetch properties. Reason: ${res.status} - ${res.statusText} - ${errMsg}`
    );
  }

  return res.json();
}

export default function useFetchPropertyByDevelopmentAndFlatId({
  developmentId,
  flatId,
  queryConfig,
}: UseFetchPropertyByDevelopmentAndFlatIdProps) {
  const url = `${process.env.GATSBY_API_HOST}/api/property/${developmentId}/${flatId}`;

  return useQuery<FetchPropertyByDevelopmentAndFlatIdSuccessResponse, Error>(
    [url],
    () => fetchPropertyByDevelopmentAndFlatId(url),
    queryConfig
  );
}
