import * as React from 'react';
import { Box } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, PlayArrow } from '@material-ui/icons';
import clsx from 'clsx';
import {
  ImageData,
  MediaEntries,
  MediaEntry,
  PropertyV2,
  VideoData,
} from '../../../../../../api/property/types';
import DialogGalleryImages from '../../../../../organisms/ImageGallery/DialogGalleryImages';
import {
  filterMediaEntriesByCategory,
  getImageUrl,
  getMediaEntries,
} from '../../utils';
import { OrgFileCategory } from '../../../../../../api/orgs/types';
import CloudinaryPicture, {
  CloudinaryPictureSizeConfig,
} from '../../../../../atoms/CloudinaryPicture/CloudinaryPicture';
import useStyles from './ImageCarousel.styles';
import EmptyImage from './EmptyImage';
import TextOverlayImage from './TextOverlayImage';
import { useSwipeable } from 'react-swipeable';
import { pushOrgImageIdDisplayedToSessionStorage } from '../../../../../../utils/storage/sessionStorage';

export interface ImageCarouselProps {
  property: PropertyV2;
  propertyImages: ImageData[];
  propertyVideos: VideoData[] | [];
}

const SIDE_IMAGES_COUNT = 5;

const SIDE_IMAGE_CLOUDINARY_CONFIG: CloudinaryPictureSizeConfig = {
  '0': {
    width: 125,
  },
};

const MAIN_IMAGE_CLOUDINARY_CONFIG: CloudinaryPictureSizeConfig = {
  '0-600': {
    width: 600,
  },
  '601': {
    width: 870,
  },
};

export default function ImageCarousel({
  propertyImages,
  propertyVideos,
}: ImageCarouselProps) {
  const originalMediaEntries = getMediaEntries(
    propertyImages,
    propertyVideos
  ).filter(
    ([, imageData]) => imageData.category !== OrgFileCategory.FLOOR_PLAN_IMAGE
  );

  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [startIndex, setStartIndex] = React.useState(0);
  const [activeCategory, setActiveCategory] = React.useState('all');
  const [mediaEntries, setMediaEntries] = React.useState(originalMediaEntries);
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        if (startIndex < originalMediaEntries.length - 1) {
          setStartIndex(startIndex + 1);
        }
      } else if (eventData.dir === 'Right') {
        if (startIndex > 0) {
          setStartIndex(startIndex - 1);
        }
      }
    },
  });

  const openDialogGalleryImages = (): void => {
    setOpenDialog(true);
  };

  const closeDialogGalleryImages = (): void => {
    setOpenDialog(false);
    setActiveCategory('all');
    setMediaEntries(originalMediaEntries);
  };

  const handleSideImageMouseOver = (index: number) => {
    setStartIndex(index);
  };
  const handleSetMediaDisplayed = (media: ImageData) => {
    pushOrgImageIdDisplayedToSessionStorage(media.orgimages_id);
  };

  const getSideElements = React.useCallback((): React.ReactNode[] => {
    const sideImgEls: React.ReactNode[] = [];

    if (originalMediaEntries.length > 0) {
      const limit = Math.min(originalMediaEntries.length, SIDE_IMAGES_COUNT);
      const remaining = Math.max(SIDE_IMAGES_COUNT - limit, 0);

      for (let imageIndex = 0; imageIndex < limit; imageIndex++) {
        const [imgId, imageData]: [string, ImageData] =
          originalMediaEntries[imageIndex];

        const imageUrl = getImageUrl(imageData);
        sideImgEls.push(
          <Box key={imgId} className={classes.sideImgCtn}>
            <CloudinaryPicture
              imgSrc={imageUrl}
              imgAlt="property image"
              config={SIDE_IMAGE_CLOUDINARY_CONFIG}
              imgProps={{
                id: imgId,
                className: clsx(
                  classes.img,
                  imageIndex !== startIndex && classes.lowOpacity
                ),
                onMouseOver: () => handleSideImageMouseOver(imageIndex),
                onClick: openDialogGalleryImages,
              }}
            />
            {imageData.src_video_url && (
              <Box
                className={clsx(classes.playIcon, classes.playThumbnailIcon)}
              >
                <PlayArrow />
              </Box>
            )}
          </Box>
        );
      }

      for (let imageIndex = 0; imageIndex < remaining; imageIndex++) {
        sideImgEls.push(
          <Box key="empty image" className={classes.sideImgCtn}>
            <EmptyImage />
          </Box>
        );
      }

      if (remaining === 0) {
        sideImgEls.push(
          <Box key="view all photos" className={classes.sideImgCtn}>
            <TextOverlayImage
              text={`View all ${originalMediaEntries.length} photos`}
              onClick={openDialogGalleryImages}
            />
          </Box>
        );
      }
    } else {
      for (let imageIndex = 0; imageIndex < SIDE_IMAGES_COUNT; imageIndex++) {
        sideImgEls.push(<EmptyImage key={`empty-${imageIndex}`} />);
      }
    }

    return sideImgEls;
  }, [originalMediaEntries]);

  const filterImagesByCategory = (category: OrgFileCategory | 'all') => {
    const filteredMediaEntries: MediaEntries = filterMediaEntriesByCategory(
      originalMediaEntries,
      category
    );
    if (category === 'all') {
      setMediaEntries(originalMediaEntries);
    } else {
      setMediaEntries(filteredMediaEntries);
    }
    setStartIndex(0);
    setActiveCategory(category);
  };

  const sideImgEls: React.ReactNode[] = getSideElements();

  const renderBigImage = ([, mediaData]: MediaEntry) => (
    <Box className={classes.imageThumbnail}>
      <CloudinaryPicture
        imgSrc={mediaData.src_url}
        imgAlt="property image"
        config={MAIN_IMAGE_CLOUDINARY_CONFIG}
        imgProps={{
          className: classes.img,
          onClick: openDialogGalleryImages,
          onLoad: () => handleSetMediaDisplayed(mediaData),
        }}
      />
      {mediaData.src_video_url && (
        <Box className={classes.playIcon}>
          <PlayArrow />
        </Box>
      )}
      {startIndex > 0 && (
        <Box
          className={classes.prev}
          onClick={() => setStartIndex(startIndex - 1)}
        >
          <ArrowBackIos />
        </Box>
      )}
      {startIndex < originalMediaEntries.length - 1 && (
        <Box
          className={classes.next}
          onClick={() => setStartIndex(startIndex + 1)}
        >
          <ArrowForwardIos />
        </Box>
      )}
    </Box>
  );

  return (
    <Box {...swipeHandlers} className={classes.ctn}>
      <Box className={classes.mainImgCtn}>
        {originalMediaEntries &&
          originalMediaEntries[startIndex] &&
          renderBigImage(originalMediaEntries[startIndex])}
      </Box>

      <Box className={classes.sideImgsCtn}>{sideImgEls}</Box>

      <DialogGalleryImages
        closeDialogGalleryImages={closeDialogGalleryImages}
        filterImagesByCategory={filterImagesByCategory}
        openDialog={openDialog}
        mediaEntries={mediaEntries}
        originalMediaEntries={originalMediaEntries}
        startIndex={startIndex}
        activeCategory={activeCategory}
        isTracking={true}
      />
    </Box>
  );
}
