import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserInDb } from '../types';

export function useAddDevelopmentToFavourites() {
  const queryClient = useQueryClient();

  return useMutation(addDevelopmentToFavourites, {
    onSuccess: (data, variables) => {
      void queryClient.setQueryData(
        [
          `${process.env.GATSBY_API_HOST}
          /api/me/myDetails`,
          { authToken: variables.authToken },
        ],
        { user: data.meta.user, userId: data.meta.user.user_id }
      );
    },
  });
}

export function useRemoveDevelopmentFromFavourites() {
  const queryClient = useQueryClient();

  return useMutation(removeDevelopmentFromFavourites, {
    onSuccess: (data, variables) => {
      void queryClient.setQueryData(
        [
          `${process.env.GATSBY_API_HOST}/api/me/myDetails`,
          { authToken: variables.authToken },
        ],
        { user: data.meta.user, userId: data.meta.user.user_id }
      );
    },
  });
}

export async function addDevelopmentToFavourites({
  developmentId,
  authToken,
}: {
  developmentId: number;
  authToken: string;
}): Promise<{
  data: { user_id: string; development_id: string };
  meta: { user: UserInDb };
}> {
  const url = `${process.env.GATSBY_API_HOST}/api/me/favouriteDevelopments`;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      developmentId,
    }),
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json();
}

export async function removeDevelopmentFromFavourites({
  developmentId,
  authToken,
}: {
  developmentId: number;
  authToken: string;
}): Promise<{
  data: { user_id: string; development_id: string };
  meta: { user: UserInDb };
}> {
  const url = `${process.env.GATSBY_API_HOST}/api/me/favouriteDevelopments`;

  const res = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${authToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      developmentId,
    }),
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  return res.json();
}
