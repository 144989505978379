import * as React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '../../components/atoms';
import PropertySummaryTextRow from '../../components/pages/properties/single-view/MainSection/PropertySummaryTextRow';
import LongSpace from '../../components/atoms/LongSpace/LongSpace';

// ========== TYPES ========== //

interface ComponentProps {
  icon: React.FunctionComponent;
  label: string;
  sectionAnchorUrl: string; // This is the anchor URL of this property attribute
  attributes: string[]; // An array of attributes to be displayed, the count of which is always less than or equal to 'attributesCount'.
  attributesCount: number; // Total attributes
}

// ========== COMPONENT ========== //

export default function PropertyAttributesRow({
  icon,
  label,
  sectionAnchorUrl,
  attributes,
  attributesCount,
}: ComponentProps) {
  return (
    <PropertySummaryTextRow icon={icon}>
      <Typography>
        <span>{label}: </span>
        {attributes.length > 0 ? attributes.join(', ') : `N/A`}
        <LongSpace />
        {attributesCount > 0 && attributes.length < attributesCount ? (
          <Link to={sectionAnchorUrl}>
            +{attributesCount - attributes.length}
          </Link>
        ) : null}
      </Typography>
    </PropertySummaryTextRow>
  );
}
