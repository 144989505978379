import * as React from 'react';
import { Box } from '@material-ui/core';
import Section from '../Common/Section';
import FlatCard from './FlatCard';
import { PropertyV2 } from '../../../../../api/property/types';
import useStyles from './styles';
import { Typography } from '../../../../atoms';
import { PropertySingleViewSection } from '../config';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2[];
}

// ========== COMPONENT ========== //

export default function FlatsSection({ data }: ComponentProps) {
  const classes = useStyles();

  if (data.length === 0) {
    return null;
  }

  const flatCardElsArray = Object.values(data);

  // Sort flat cards ascending based on bedrooms count
  flatCardElsArray.sort((a, b) => {
    return a.bedrooms_count - b.bedrooms_count;
  });

  const flatCardEls = flatCardElsArray.map((property) => (
    <FlatCard key={property.flat_id} data={property} />
  ));

  return (
    <Section
      title="Available properties"
      isGrey
      id={PropertySingleViewSection.AVAILABLE_PROPERTIES_SECTION}
    >
      <Box className={classes.ctn}>
        <Box className={classes.flatCardsCtn}>{flatCardEls}</Box>
      </Box>
      <Box className={classes.flatDisclaimerCtn}>
        <Typography variant="body2">
          <i>
            Prices, images, videos, floor plans and dimensions are for
            illustrative purposes only and should not be relied upon for any
            contract. You should always check with the connected company before
            entering a tenancy agreement.
          </i>
        </Typography>
      </Box>
    </Section>
  );
}
