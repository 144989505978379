import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },

    titleAndExpandBtnCtn: {
      display: 'flex',
      alignItems: 'center',
    },

    title: {
      flex: `1 1 auto`,
      fontWeight: 'bold',
    },

    attributesCtn: {
      display: 'grid',
      gridTemplateColumns: `1fr 1fr`,
      gap: theme.spacing(0.5),
    },

    rentAndSelectBtnCtn: {
      display: 'flex',
    },

    rent: {
      flex: `1 1 auto`,
      marginRight: theme.spacing(2),
    },

    rentAmt: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  })
);
