import * as React from 'react';
import {
  Breadcrumbs as BreadcrumbsMUI,
  Link,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import Section from '../Common/Section';

interface ComponentProps {
  title: string;
  city?: string;
}

export default function Breadcrumbs({ title, city }: ComponentProps) {
  const classes = useStyles();

  return (
    <Section className={classes.breadcrumb}>
      <BreadcrumbsMUI aria-label="navigation links">
        <Link color="inherit" href="/">
          Home
        </Link>
        {city && (
          <Link
            color="inherit"
            href={`/properties/?address=${city}&radius=5000`}
          >
            {city}
          </Link>
        )}
        <Typography color="textPrimary">{title}</Typography>
      </BreadcrumbsMUI>
    </Section>
  );
}
