import { PropertyV2 } from '../../../../../api/property/types';

export enum ExpandedDetailsCategories {
  PROPERTY_FACILITIES = 'Property facilities',
  FURNITURE = 'Furniture',
  APPLIANCES = 'Appliances',
  'ATMOSPHERE' = 'Atmosphere',
}

export const expandedDetailsCategories: Record<
  ExpandedDetailsCategories,
  Array<keyof PropertyV2>
> = {
  [ExpandedDetailsCategories.PROPERTY_FACILITIES]: [
    'fttr_balcony',
    'attr_bathroom_type',
    'attr_furnished',
    'fttr_open_plan_kitchen',
    'fttr_smart_meter',
    'attr_video_door_entry',
  ],
  [ExpandedDetailsCategories.FURNITURE]: [
    'fttr_bed_and_mattress',
    'fttr_bed_size',
    'fttr_built_in_wardrobe',
    'fttr_coffee_table',
    'fttr_dining_table_and_chairs',
    'fttr_drawers',
    'fttr_sofa',
    'fttr_tv',
    'fttr_tv_stand',
  ],
  [ExpandedDetailsCategories.APPLIANCES]: [
    'fttr_dishwasher',
    'fttr_dryer',
    'fttr_fridge',
    'fttr_hob_type',
    'fttr_microwave',
    'fttr_oven',
    'fttr_washing_machine',
  ],
  [ExpandedDetailsCategories.ATMOSPHERE]: [
    'fttr_family_friendly',
    'fttr_pet_friendly',
    'fttr_student_friendly',
  ],
};

/**
 * Given a category, return an array of attributes in that category that are
 * "truthy" (not undefined, not false)
 */
export function getExpandedDetailsCategoryTruthyAttributes(
  category: ExpandedDetailsCategories,
  data: PropertyV2
): Array<keyof PropertyV2> {
  return expandedDetailsCategories[category].filter(
    (attribute) => data[attribute]
  );
}
