import * as React from 'react';
import {
  Box,
  Container,
  createStyles,
  Fab,
  makeStyles,
  Theme,
  Zoom,
} from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import TimeMe from 'timeme.js';

import { PropertyV2 } from '../../../../api/property/types';
import useFetchPropertyImages from '../../../../api/property/useFetchPropertyImages';
import { AreaDetailsFromApi } from '../../../../api/property/usePropertyAreaDetails';
import {
  addRecentlyViewedProperty,
  PropertyRecentlyViewed,
} from '../../../../api/utils/localStorage';
import useFetchPropertyVideos from '../../../../api/property/useFetchPropertyVideos';
import { NearbyPlace } from '../../../../models/nearbyPlaces';

import { isVideoByExt } from '../../../../utils/file';
import { getAddressFromPropertyData } from '../../../../utils/address';
import { swapArrayItem } from '../../../../utils/array';
import {
  gtmDataLayerPSVOrgImagePush,
  gtmDataLayerPSVSpendTimeSectionsPush,
} from '../../../../utils/marketing/eventPushes/gtmDataLayerPSV';
import { CustomVariables } from '../../../../utils/marketing/variables';

import MainSection from './MainSection';
import FacilitiesAndServicesSection from './FacilitiesAndServicesSection';
import FlatsSection from './FlatsSection';
import MapSection from './MapSection';
import BillsSection from './BillsSection';
import WhyRentWithUs from '../WhyRentWithUs';
import TalkToUs from '../../../organisms/TalkToUs';
import BreadcrumbsView from './Breadcrumbs';
import SimilarPropertiesSection from './SimilarProperties';
import { extractExtensionFromName } from './utils';
import AreaGuide from './AreaGuide';
import AreaGuideMobile from './AreaGuideMobile';
import HeaderStickyProperty from './HeaderStickyProperty';
import VirtualTour from './VirtualTour';
import Disclaimer from './Disclaimer/Disclaimer';
import { PropertySingleViewSection } from './config';
import {
  getOrgImageIdsDisplayedFromSessionStorage,
  resetOrgImageIdsDisplayedFromSessionStorage,
} from '../../../../utils/storage/sessionStorage';
import { gtmDataLayerSessionPush } from '../../../../utils/marketing/eventPushes/gtmDataLayerSession';
import AdUnitContainer from '../AdUnitContainer/AdUnitContainer';
import { AdUnit } from '../../../atoms';

// ========== TYPES ========== //

interface ComponentProps {
  data: PropertyV2[];
  nearbyPlaces: NearbyPlace[];
  bills: [];
  developmentDetails: string;
  areaGuide?: AreaDetailsFromApi[];
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: theme.spacing(2, 0),
      background: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },

    header: {
      textAlign: 'center',
    },

    phoneButton: {
      fontWeight: 'bold',
      position: 'fixed',
      bottom: 30,
      right: 15,
      zIndex: 1,
      '& svg': {
        // marginRight: theme.spacing(1),
      },
    },
    emailButton: {
      fontWeight: 'bold',
      position: 'fixed',
      bottom: 30,
      right: 80,
      zIndex: 1,
      '& svg': {
        // marginRight: theme.spacing(1),
      },
    },
    adCtn: {
      margin: '1rem auto',
    },
  })
);

// ========== COMPONENT ========== //

function getCheapestRent(data: PropertyV2[]): number {
  const nonZeroRentPcms = data
    .map(({ rent_pcm }) => Number(rent_pcm))
    .filter((rentPcm) => rentPcm > 0);

  return nonZeroRentPcms.reduce<number>(
    (cheapest, rentPcm) => (rentPcm < cheapest ? rentPcm : cheapest),
    Infinity
  );
}

const adUnitSlotIds: string[] = [
  process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_SINGLE_VIEW_INPAGE_01_SLOT_ID ??
    '',

  // TODO: re-enable when we confirm UX is fine
  // process.env.GATSBY_GOOGLE_ADSENSE_PROPERTY_SINGLE_VIEW_INPAGE_02_SLOT_ID ?? '',
];

export default function PropertySingleView({
  data,
  nearbyPlaces,
  bills,
  developmentDetails,
  areaGuide,
}: ComponentProps) {
  const classes = useStyles();
  const property = data[0];
  const [showContactFab, setShowContactFab] = React.useState(false);

  const { data: propertyImagesResponse, status: propertyImagesStatus } =
    useFetchPropertyImages({
      developmentId: property.development_id,
      queryConfig: {
        staleTime: Infinity,
      },
    });

  const { data: propertyVideosResponse, status: propertyVideosStatus } =
    useFetchPropertyVideos({
      developmentId: property.development_id,
      queryConfig: {
        staleTime: Infinity,
      },
    });

  let developmentImages = propertyImagesResponse?.data?.developmentImages || [];
  const developmentVideos =
    propertyVideosResponse?.data?.developmentVideos || [];

  const allFlatImages =
    propertyImagesResponse?.data?.flatImages?.reduce(
      (allFlatImages, flatImages) => {
        return [...allFlatImages, ...flatImages];
      },
      []
    ) || [];

  const allFlatVideos =
    propertyVideosResponse?.data?.flatVideos?.reduce(
      (allFlatVideos, flatVideos) => {
        return [...allFlatVideos, ...flatVideos];
      },
      []
    ) || [];

  const cheapestRent = getCheapestRent(data);

  const firstImage = developmentImages.find(
    (developmentImage) =>
      !isVideoByExt(extractExtensionFromName(developmentImage.original_name))
  );

  // swap array item development images
  developmentImages.map((imgDevelopment, index) => {
    if (
      property.img_thumbnail_id &&
      imgDevelopment.orgimages_id === property.img_thumbnail_id
    ) {
      developmentImages = swapArrayItem(developmentImages, 0, index);
    }
  });

  const propertyRecentlyViewed: PropertyRecentlyViewed = {
    developmentId: property.development_id,
    title: property.title,
    price: cheapestRent,
    src: firstImage?.src_url ?? '',
  };

  if (firstImage) addRecentlyViewedProperty(propertyRecentlyViewed);

  const hasBills = bills.length > 0;

  const headerHiddenItems: string[] = [];

  if (!hasBills) {
    headerHiddenItems.push('bills');
  }

  const pushTrackingTime = () => {
    const keys = {
      [CustomVariables.SPEND_TIME_SUMMARY_SEC]:
        PropertySingleViewSection.MAIN_SECTION,
      [CustomVariables.SPEND_TIME_AVAILABLE_PROPERTIES_SEC]:
        PropertySingleViewSection.AVAILABLE_PROPERTIES_SECTION,
      [CustomVariables.SPEND_TIME_FACILITIES_SERVICES_SEC]:
        PropertySingleViewSection.FACILITIES_SERVICES_SECTION,
      [CustomVariables.SPEND_TIME_MAP_SEC]:
        PropertySingleViewSection.MAP_SECTION,
      [CustomVariables.SPEND_TIME_VIRTUAL_TOUR_SEC]:
        PropertySingleViewSection.VIRTUAL_TOUR_SECTION,
      [CustomVariables.SPEND_TIME_AREA_GUIDE_SEC]:
        PropertySingleViewSection.AREA_GUIDE_SECTION,
      [CustomVariables.SPEND_TIME_SIMILAR_PROPERTIES_SEC]:
        PropertySingleViewSection.SIMILAR_PROPERTIES_SECTION,
    };
    gtmDataLayerPSVSpendTimeSectionsPush({
      developmentId: property.development_id,
      ...Object.keys(keys).reduce((obj, variable) => {
        obj[variable] = TimeMe.getTimeOnElementInSeconds(keys[variable]);
        return obj;
      }, {} as Record<string, number>),
    });
  };
  const pushTrackingOrgImages = () => {
    const orgImageIds = getOrgImageIdsDisplayedFromSessionStorage();

    for (const orgImageId of orgImageIds) {
      gtmDataLayerPSVOrgImagePush({
        [CustomVariables.ORG_IMAGE_ID]: orgImageId,
      });
    }
    resetOrgImageIdsDisplayedFromSessionStorage();
  };

  const adUnitElement = adUnitSlotIds.map((slotId) => (
    <Container maxWidth="lg" key={slotId} className={classes.adCtn}>
      <AdUnitContainer>
        <AdUnit adSlot={slotId} />
      </AdUnitContainer>
    </Container>
  ));

  React.useEffect(() => {
    // Start tracking time spent by section
    if (
      property.development_id &&
      propertyImagesStatus === 'success' &&
      propertyVideosStatus === 'success'
    ) {
      TimeMe.initialize({
        currentPageName: `property-page-${property.development_id}`,
        idleTimeoutInSeconds: 5,
      });
      TimeMe.trackTimeOnElement(PropertySingleViewSection.MAIN_SECTION);
      TimeMe.trackTimeOnElement(
        PropertySingleViewSection.AVAILABLE_PROPERTIES_SECTION
      );
      TimeMe.trackTimeOnElement(
        PropertySingleViewSection.FACILITIES_SERVICES_SECTION
      );
      TimeMe.trackTimeOnElement(PropertySingleViewSection.MAP_SECTION);
      TimeMe.trackTimeOnElement(PropertySingleViewSection.VIRTUAL_TOUR_SECTION);
      TimeMe.trackTimeOnElement(PropertySingleViewSection.AREA_GUIDE_SECTION);
      TimeMe.trackTimeOnElement(
        PropertySingleViewSection.SIMILAR_PROPERTIES_SECTION
      );

      // handle before when refresh or close page
      window.onbeforeunload = () => {
        pushTrackingTime();
        pushTrackingOrgImages();
        gtmDataLayerSessionPush();
      };
    }
  }, [property.development_id, propertyImagesStatus, propertyVideosStatus]);

  // Update time tracking when component unmounts
  React.useEffect(() => {
    return function cleanup() {
      pushTrackingTime();
    };
  }, []);

  return (
    <Box className={classes.ctn}>
      <BreadcrumbsView title={data[0].title} city={data[0].city} />
      {propertyImagesStatus === 'loading' ||
      propertyVideosStatus === 'loading' ? (
        <span>Loading...</span>
      ) : propertyImagesStatus === 'success' &&
        propertyVideosStatus === 'success' ? (
        <MainSection
          property={property}
          nearbyPlaces={nearbyPlaces}
          bills={bills}
          cheapestRent={cheapestRent}
          developmentImages={developmentImages}
          developmentVideos={developmentVideos}
          flatImages={allFlatImages}
          flatVideos={allFlatVideos}
          developmentDetails={developmentDetails}
          data={data}
          setShowContactFab={setShowContactFab}
        />
      ) : null}
      <Box>
        <HeaderStickyProperty data={data} hideItems={headerHiddenItems} />
        <FlatsSection data={data} />
        <FacilitiesAndServicesSection data={data[0]} />
        {hasBills && <BillsSection bills={bills} />}
        <MapSection
          geometry={data[0].geographyJson}
          nearbyPlaces={nearbyPlaces}
          address={property.address}
        />
        {adUnitElement}
        {property.virtual_tour_url && (
          <VirtualTour src={property.virtual_tour_url} />
        )}
        <div id={PropertySingleViewSection.AREA_GUIDE_SECTION}>
          <AreaGuideMobile
            areaGuide={areaGuide}
            developmentId={property.development_id}
          />
          <AreaGuide
            areaGuide={areaGuide}
            developmentId={property.development_id}
          />
        </div>
        <div id={PropertySingleViewSection.SIMILAR_PROPERTIES_SECTION}>
          <SimilarPropertiesSection
            development_id={property.development_id}
            address={getAddressFromPropertyData(property)}
          />
          <Disclaimer />
        </div>
        <WhyRentWithUs isClassGray={true} />
        <TalkToUs />
        {property.contact_phone && (
          <Zoom
            in={showContactFab}
            exit={true}
            timeout={500}
            style={{
              transitionDelay: `200ms`,
            }}
            unmountOnExit
          >
            <Fab
              color="primary"
              variant="extended"
              href={`tel:${property.contact_phone}`}
              className={classes.phoneButton}
            >
              <Phone />
            </Fab>
          </Zoom>
        )}

        {/* TODO: re-enable this when analytics on this button is set up  */}
        {/*{property.contact_email && (*/}
        {/*  <Zoom*/}
        {/*    in={showContactFab}*/}
        {/*    exit={true}*/}
        {/*    timeout={500}*/}
        {/*    style={{*/}
        {/*      transitionDelay: `200ms`,*/}
        {/*    }}*/}
        {/*    unmountOnExit*/}
        {/*  >*/}
        {/*    <Fab*/}
        {/*      color="primary"*/}
        {/*      variant="extended"*/}
        {/*      href={`mailto:${property.contact_email}`}*/}
        {/*      className={classes.emailButton}*/}
        {/*    >*/}
        {/*      <Email />*/}
        {/*      /!* {parsePhoneNumber(property.contact_phone, 'GB')?.formatNational()} *!/*/}
        {/*    </Fab>*/}
        {/*  </Zoom>*/}
        {/*)}*/}
      </Box>
    </Box>
  );
}
