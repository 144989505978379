import * as React from 'react';
import { Box, colors, Typography } from '@material-ui/core';
import useStyles from './styles/PropertyAttributeSection';
import { PropertyV2 } from '../../../../../api/property/types';
import PropertyAttribute from './PropertyAttribute';
import PropertyAttributeIcon from './PropertyAttributeIcon';

// ========== TYPES ========== //

interface ComponentProps {
  title: string;
  attributes: Array<{
    attributeName: keyof PropertyV2;
    attributeLabel: string;
  }>;
}

// ========== COMPONENT ========== //

const attributeIconColor = colors.grey['900'];

export default function PropertyAttributesSection({
  title,
  attributes,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.ctn}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      <Box className={classes.attributesCtn}>
        {attributes.map(({ attributeName, attributeLabel }) => (
          <PropertyAttribute
            key={attributeName}
            icon={
              <PropertyAttributeIcon
                stroke={attributeIconColor}
                fontSize="large"
                attribute={attributeName}
              />
            }
            text={attributeLabel}
          />
        ))}
      </Box>
    </Box>
  );
}
