import { ScreenType } from '../../config';

/**
 * Given the window's width, return a screen type string. To be used as a
 * utility in conjunction with styling solutions elsewhere.
 *
 * If the window's width is undefined, default to MOBILE.
 *
 * @param {number | undefined} windowWidth - The current window's width
 *
 * @returns {ScreenType} - The calculated screen type. Default to MOBILE.
 */
export default function determineScreenType(
  windowWidth: number | undefined
): ScreenType {
  if (!windowWidth) {
    return ScreenType.MOBILE;
  }

  if (windowWidth >= 1280) {
    return ScreenType.DESKTOP;
  }

  if (windowWidth >= 960) {
    return ScreenType.TABLET_LANDSCAPE;
  }

  if (windowWidth >= 768) {
    return ScreenType.TABLET_PORTRAIT;
  }

  return ScreenType.MOBILE;
}
