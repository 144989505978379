import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainCtn: {
      width: '100%',

      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: `5fr 2fr`,
        columnGap: theme.spacing(2),
      },
    },

    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },

    priceDetailsCtn: {},
  })
);
