import React, { useState, useMemo } from 'react';
import { Box, Typography, ClickAwayListener } from '@material-ui/core';
import {
  Room,
  School,
  Telegram,
  Close,
  LocalGroceryStore,
  ShoppingBasket,
} from '@material-ui/icons';
import TflBus from '../../../images/iconsTfl/TflBus';
import TflTube from '../../../images/iconsTfl/TflTube';
import TflDlr from '../../../images/iconsTfl/TflDlr';
import TflRail from '../../../images/iconsTfl/TflRail';
import clsx from 'clsx';
import useStyles from './styles/Pin.styles';
import { formatNumber, metersToMiles } from '../../../utils/number';

interface ComponentProps {
  lat?: number;
  lng?: number;
  $hover?: boolean;
  name?: string;
  img?: string;
  type?: string;
  distance?: string;
}

const Pin = ({
  $hover: hover,
  name,
  distance,
  type = 'unknown',
}: ComponentProps) => {
  const classes = useStyles();
  const [isShowInfo, setShowInfo] = useState(false);

  const showPopupInfo = () => {
    setShowInfo(true);
  };

  const closePopupInfo = () => {
    setShowInfo(false);
  };

  const inforDetail = useMemo(() => {
    const distanceMiles = metersToMiles(Number(distance));
    const distanceMilesStr = formatNumber(distanceMiles, {
      decimalPlaces: 1,
    });
    return (
      <Box className={classes.pinClick}>
        <Close
          fontSize="inherit"
          className={classes.closeBtn}
          onClick={closePopupInfo}
        />

        {name && (
          <Box className={clsx(classes.infomation, classes.fontBold)}>
            {type === 'unknown' && <Room />}
            {type === 'train_station' && <TflRail />}
            {type === 'subway_station' && <TflTube />}
            {type === 'light_rail_station' && <TflDlr />}
            {type === 'bus_station' && <TflBus />}
            {type === 'supermarket' && <LocalGroceryStore />}
            {type === 'store' && <ShoppingBasket />}
            {type === 'school' && <School />}
            <Typography>{name}</Typography>
          </Box>
        )}

        {distance && (
          <Box className={classes.infomation}>
            <Telegram fontSize="small" />
            <Typography>
              {`${distanceMilesStr} ${
                distanceMilesStr === '1.0' ? 'mile' : 'miles'
              }`}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }, []);

  return (
    <ClickAwayListener onClickAway={closePopupInfo}>
      <Box className={clsx(classes.pin, hover ? classes.pinHover : '')}>
        {isShowInfo && inforDetail}
        <Box onClick={showPopupInfo}>
          {type === 'unknown' && <Room className={classes.roomIcon} />}
          {type === 'train_station' && (
            <TflRail className={classes.stationIcon} />
          )}
          {type === 'subway_station' && (
            <TflTube className={classes.stationIcon} />
          )}
          {type === 'light_rail_station' && (
            <TflDlr className={classes.stationIcon} />
          )}
          {type === 'bus_station' && <TflBus className={classes.stationIcon} />}
          {type === 'supermarket' && (
            <LocalGroceryStore className={classes.storeIcon} />
          )}
          {type === 'store' && <ShoppingBasket className={classes.storeIcon} />}
          {type === 'school' && <School className={classes.schoolIcon} />}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Pin;
