import { createStyles, makeStyles, Theme, colors } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      border: `1px solid ${colors.grey['200']}`,
    },

    linkCtn: {
      height: '100%',
    },

    contentCtn: {
      display: 'flex',
      flexDirection: 'column',
      flex: `1 0 auto`,
      height: '100%',
    },

    subContent1Ctn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      padding: theme.spacing(1, 2),
    },

    subContent2Ctn: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      flex: `1 0 auto`,
      padding: theme.spacing(1, 2, 2),
      backgroundColor: colors.grey['100'],
      '& p': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
    },

    title: {
      fontWeight: 'bold',
      width: '100%',
    },

    cheapestRentAmt: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },

    address: {
      display: 'flex',
    },

    addressIcon: {
      fontSize: '1.25rem',
      color: theme.palette.secondary.main,
    },

    ellipsisText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    nearby: {
      display: 'flex',
      marginBottom: theme.spacing(0.5),
      '& span': {
        lineHeight: 1.3,
      },
    },

    stationIcon: {
      color: '#3258A8',
      fontSize: '1.25em',
    },

    storeIcon: {
      color: theme.palette.secondary.main,
    },

    schoolIcon: {
      color: theme.palette.secondary.main,
    },

    colorLight: {
      color: '#B2B2B2',
    },
  })
);
