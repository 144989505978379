import * as React from 'react';
import { Router } from '@reach/router';
import GenericPage from '../../components/layout/CommonLayout/GenericPage';
import PropertySingleViewPage from '../../components/pages/properties/single-view/PropertySingleViewPage';
import PropertyListPage from '../../components/pages/properties/PropertyListPage';
import ClientOnly from '../../components/atoms/ClientOnly/ClientOnly';
import useAnalyticsSessionData from '../../hooks/useAnalyticsSessionData';

export default function Properties() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <ClientOnly>
        <Router basepath="/properties">
          <PropertySingleViewPage path="/:developmentId" />
          <PropertyListPage path="/" />
        </Router>
      </ClientOnly>
    </GenericPage>
  );
}
