import * as React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './ClaimThisDevelopment.styles';
import { Button, Link, Typography } from '../../../../../atoms';

interface LogoAndNameData {
  url: string;
  logoSrc: string;
  name: string;
}

export interface ClaimThisDevelopmentProps {
  managerData?: LogoAndNameData;
  ownerData?: LogoAndNameData;

  handleClaimThisDevelopmentButtonClick: () => void;
}

interface LogoAndNamePairProps extends LogoAndNameData {
  title: string;
}

function LogoAndNamePair({ logoSrc, name, title, url }: LogoAndNamePairProps) {
  const classes = useStyles();

  return (
    <Link to={url} noColor>
      <Box className={classes.logoAndNamePairCtn}>
        <img className={classes.logoImg} src={logoSrc} alt={`${name}'s logo`} />

        <Typography variant="body2" align="right">
          <b>{title}:</b> {name}
        </Typography>
      </Box>
    </Link>
  );
}

export default function ClaimThisDevelopment({
  managerData,
  ownerData,
  handleClaimThisDevelopmentButtonClick,
}: ClaimThisDevelopmentProps) {
  const classes = useStyles();

  const logoAndNamePairs: React.ReactNode[] = [];
  if (managerData && ownerData && managerData.name === ownerData.name) {
    // When the manager is also the owner, only display one logo & name pair
    logoAndNamePairs.push(
      <LogoAndNamePair
        url={managerData.url}
        logoSrc={managerData.logoSrc}
        name={managerData.name}
        title="Manager & owner"
      />
    );
  } else {
    if (managerData) {
      logoAndNamePairs.push(
        <LogoAndNamePair
          key={managerData.name}
          url={managerData.url}
          logoSrc={managerData.logoSrc}
          name={managerData.name}
          title="Manager"
        />
      );
    }

    if (ownerData) {
      logoAndNamePairs.push(
        <LogoAndNamePair
          key={ownerData.name}
          url={ownerData.url}
          logoSrc={ownerData.logoSrc}
          name={ownerData.name}
          title="Owner"
        />
      );
    }
  }

  return (
    <Box className={classes.claimThisDevelopmentCtn}>
      {logoAndNamePairs.length > 0 && (
        <Box className={classes.managerAndOwnerSectionCtn}>
          {logoAndNamePairs}
        </Box>
      )}

      <Box>
        <Button
          onClick={handleClaimThisDevelopmentButtonClick}
          id="claim-development-button"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.claimThisDevelopmentButton}
        >
          Claim this development
        </Button>
      </Box>
    </Box>
  );
}
