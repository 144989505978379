import { ScreenType } from '../../../config';
import { GetCloudinaryPublicImageUrlOpts } from '../../../libs/cloudinary/getCloudinaryImageUrl';

export const configResizeGalleryImage: Record<
  ScreenType,
  {
    original: GetCloudinaryPublicImageUrlOpts;
    thumbnail: GetCloudinaryPublicImageUrlOpts;
  }
> = {
  desktop: {
    original: { cropResize: { mode: 'limit', width: 1440 } },
    thumbnail: { cropResize: { mode: 'limit', width: 96 } },
  },
  tablet_landscape: {
    original: { cropResize: { mode: 'limit', width: 1024 } },
    thumbnail: { cropResize: { mode: 'limit', width: 75 } },
  },
  tablet_portrait: {
    original: { cropResize: { mode: 'limit', width: 768 } },
    thumbnail: { cropResize: { mode: 'limit', width: 75 } },
  },
  mobile: {
    original: { cropResize: { mode: 'limit', width: 375 } },
    thumbnail: { cropResize: { mode: 'limit', width: 75 } },
  },
};
