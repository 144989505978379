import * as React from 'react';
import { Box, Container } from '@material-ui/core';
import ExpansionPanel from './ExpansionPanel';
import useStyles from './styles';
import { AreaDetailsFromApi } from '../../../../../api/property/usePropertyAreaDetails';
import MarkdownDisplay from '../../../../atoms/MarkdownDisplay/MarkdownDisplay';
import handleAreaGuideTitleClick from '../AreaGuide/handleAreaGuideTitleClick';

interface ComponentProps {
  areaGuide?: AreaDetailsFromApi[];
  developmentId: number;
}

const Section = ({ children }: { children?: React.ReactNode }) => {
  return <Box pb={4}>{children}</Box>;
};

export default function AreaGuideMobile({
  areaGuide,
  developmentId,
}: ComponentProps) {
  const classes = useStyles();
  const handleOnExpanded = (v: boolean, areaGuideTitle: string) => {
    if (v) {
      handleAreaGuideTitleClick(areaGuideTitle, developmentId);
    }
  };
  return (
    <Box className={classes.grey} p="70px 15px" width="100%">
      <Container className={classes.container}>
        <br />
        <Section>
          {Array.isArray(areaGuide) &&
            areaGuide.map((guide, index) => (
              <ExpansionPanel
                key={index}
                headerText={guide.title}
                onChange={(v) => handleOnExpanded(v, guide.title)}
              >
                <Box className={classes.content}>
                  <MarkdownDisplay markdownContent={guide.content} />
                </Box>
              </ExpansionPanel>
            ))}
        </Section>
      </Container>
    </Box>
  );
}
