import * as React from 'react';
import { Box } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import clsx from 'clsx';
import { Link, Typography } from '../../../../atoms';
import useStyles from './styles/SimilarPropertyCard';
import { formatMoney } from '../../../../../utils/number';
import { ImageData, PropertyV2 } from '../../../../../api/property/types';
import ImageCarousel from '../../ImageCarousel/ImageCarousel';
import LongSpace from '../../../../atoms/LongSpace/LongSpace';
import {
  getAddressFromPropertyData,
  getNearbyPlacesByPlaceType,
  getNearbyPlaceStations,
} from '../../../../../utils/address';
import useFetchPropertyImages from '../../../../../api/property/useFetchPropertyImages';
import useFetchPropertyVideos from '../../../../../api/property/useFetchPropertyVideos';
import NearbyPlaces from '../../PropertyCard/NearbyPlaces';
import TrainIcon from '../../PropertyCard/TrainIcon';
import { ScreenType } from '../../../../../config';
import useScreenType from '../../../../../utils/dom/useScreenType';

interface ComponentProps {
  property: PropertyV2;
}

export default function SimilarPropertyCard({ property }: ComponentProps) {
  const classes = useStyles();
  const screenType = useScreenType();

  const { data: propertyImagesResponse } = useFetchPropertyImages({
    developmentId: property.development_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  const { data: propertyVideosResponse } = useFetchPropertyVideos({
    developmentId: property.development_id,
    queryConfig: {
      staleTime: Infinity,
    },
  });

  const allDevelopmentImages =
    propertyImagesResponse?.data?.developmentImages ?? [];
  const allFlatImages = propertyImagesResponse?.data.flatImages.flat() ?? [];

  const imgSrcs: ImageData[] = [...allDevelopmentImages, ...allFlatImages];

  const imgCount =
    allDevelopmentImages.length +
    allFlatImages.length +
    (propertyVideosResponse?.data.developmentVideos.length || 0) +
    (propertyVideosResponse?.data.flatVideos.flat().length || 0);

  const nearbyPlacesByPlaceType = getNearbyPlacesByPlaceType(
    property.nearby_places ?? []
  );

  const isMobile = screenType === ScreenType.MOBILE;

  const nearbyPlaceCount = isMobile ? 2 : 3;
  const nearbyPlaceStations = getNearbyPlaceStations(
    nearbyPlacesByPlaceType,
    nearbyPlaceCount
  );

  return (
    <Box className={classes.ctn}>
      <Box>
        <ImageCarousel
          justAdded={false}
          imgSrcs={imgSrcs}
          imgHeight={250}
          imgCount={imgCount}
          imgLink={`/properties/${property.development_id}`}
        />
      </Box>

      <Link
        className={classes.linkCtn}
        fullSize
        noColor
        to={`/properties/${property.development_id}`}
      >
        <Box className={classes.contentCtn}>
          <Box className={classes.subContent1Ctn}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                className={clsx(classes.ellipsisText, classes.title)}
                variant="h6"
                component="span"
              >
                {property.title}
              </Typography>
            </Box>

            <Typography>
              From{' '}
              <span className={classes.cheapestRentAmt}>
                {formatMoney(property.rent_pcm, 'GBP', 0)}
              </span>
              /month
            </Typography>
          </Box>

          <Box className={classes.subContent2Ctn}>
            <Typography>
              <span className={classes.address}>
                <Room className={classes.addressIcon} />
                <LongSpace />
                <span>
                  {getAddressFromPropertyData({
                    address: property.address,
                    postcode: property.postcode,
                  })}
                </span>
              </span>
            </Typography>

            <Box display="flex" flexDirection="column">
              {nearbyPlaceStations.map((nearbyPlace) => (
                <NearbyPlaces
                  key={nearbyPlace.place_id}
                  places={[nearbyPlace]}
                  icon={TrainIcon}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
