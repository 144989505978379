import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';

export interface PropertySummarySubSectionProps {
  title?: string;
  sticky?: boolean;
  developmentDetailsExpanded?: boolean;
  setDevelopmentDetailsExpanded?: (boolean) => void;
  children?: React.ReactNode;
  id?: string;
}

const useStyles = makeStyles<Theme, { headerHeight: number }>((theme: Theme) =>
  createStyles({
    ctn: {
      position: 'relative',

      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      marginBottom: theme.spacing(2),
    },

    title: {
      display: 'flex',
      justifyContent: 'space-between',

      padding: theme.spacing(1.25, 0, 1, 0),

      fontWeight: 'bold',
      backgroundColor: '#ffffff',

      zIndex: 1,
    },

    sticky: ({ headerHeight }) => ({
      position: 'sticky',
      top: headerHeight, // Equals to header's height
    }),

    collapseBtn: {
      cursor: 'pointer',
    },

    childCtn: {
      width: '100%',
    },
  })
);

export default function PropertySummarySubSection({
  title,
  sticky,
  developmentDetailsExpanded,
  setDevelopmentDetailsExpanded,
  children,
  id,
}: PropertySummarySubSectionProps) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles({ headerHeight: isMobileView ? 0 : 83 });

  const handleCollapseKeydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && setDevelopmentDetailsExpanded) {
      setDevelopmentDetailsExpanded(false);
    }
  };

  const handleCollapseClick = (e: React.MouseEvent) => {
    if (setDevelopmentDetailsExpanded) {
      setDevelopmentDetailsExpanded(false);
    }
  };

  return (
    <Box id={id} className={classes.ctn}>
      {title && (
        <Typography
          variant="h6"
          className={clsx(
            classes.title,
            sticky && developmentDetailsExpanded && classes.sticky
          )}
        >
          {title}
          {developmentDetailsExpanded && sticky && (
            <span
              role="button"
              tabIndex={0}
              className={classes.collapseBtn}
              onKeyDown={handleCollapseKeydown}
              onClick={handleCollapseClick}
            >
              <ClearIcon />
            </span>
          )}
        </Typography>
      )}
      <Box className={classes.childCtn}>{children}</Box>
    </Box>
  );
}
