import * as React from 'react';
import { Link } from '@material-ui/core';
import { Typography } from '../../../../atoms';
import Section from '../Common/Section';

export default function Disclaimer() {
  return (
    <Section title="DISCLAIMER">
      <Typography>
        Information on this page is for guidance only and remains subject to
        change. <Link href="https://buildtorent.io">buildtorent.io</Link> does
        not let or manage this development. For more information about this
        development please register your interest or get in touch with the
        connect company via their website, telephone number, or email contact.
      </Typography>
    </Section>
  );
}
